
// authService - отвечает за функционирование области чата
// import { authService } from '@/service/authService';
// const { setInterface } = authService();

import {AuthMode} from '../types/authType';
// VUE
import { ref, computed} from 'vue';

const serviceName = 'authService'
//LOG-SERVICE
import {LogService} from '@/service/logService';
const log = new LogService(serviceName);
import { api } from '@/router/fetchAPI';
// import { resolve } from 'path';
// ROUTE
// import { useRouter } from 'vue-router'
// import { useRoute } from 'vue-router'
// import { Router } from 'vue-router';
// Router.p;

// const router = useRouter();
// enum AuthMode {
//     BeginState = "",
//     AwaitAuth = "AwaitAuth",
// }

// dev STATE -
// interface User {
//     id: number
//     username: string,  
//     first_name: string    
//     last_name: string
//     email: string

// }

// STATE
interface State {
    count: number;
    //   chats: Array<any>
    //   currentChatID: number,
    //   currentMessageID: number,
    //   currentMessageIndex: number,

    //   currentMessageMode: string,
    //   inputMessage: { text: string }
    tokenAuth : string,
    User: User | null; //текущая учётная запись
    users: User[];
    authStatus: string,
    isAuth: boolean;
    // interface: any,
}


// STATE
// interface ConfigState {
// //   currentChatID: number,
//   // currentMessageIndex: number,
//   // interface: any,
//   // currentMessageMode: string,
// }

// CREATE-STATE
const state = ref<State>({
    //   chats: [
    //     { id: 1, name: '11233', messages: [{ id: 1, author: 'Иван', text: 'test1' }, { id: 2, author: 'Петр', text: 'test1' }] },
    //     { id: 2, name: 'ffff', messages: [{ id: 1, author: 'Иван', text: 'test2' }, { id: 2, author: 'Петр', text: 'test2' }] },
    //     {
    //       id: 3, name: '2222', messages: [{ id: 1, author: 'Иван', text: 'test3' }, { id: 2, author: 'Петр', text: 'test3' }, { id: 3, author: 'Иван', text: 'test3' }, { id: 4, author: 'Петр', text: 'test3' }
    //         , { id: 6, author: 'Иван', text: 'test3' }, { id: 27, author: 'Петр', text: 'test3' }, { id: 91, author: 'Иван', text: 'test3' }, { id: 32, author: 'Петр', text: 'test3' }
    //         , { id: 31, author: 'Иван', text: 'test3' }, { id: 43, author: 'Петр', text: 'test3' }, { id: 55, author: 'Иван', text: 'test3' }, { id: 342, author: 'Петр', text: 'test3' }
    //       ]
    //     },
    //   ],
    //   currentChatID: 0,
    //   currentMessageID: 0,
    //   currentMessageIndex: 0,
    //   currentMessageMode: '',
    User: null , //текущая учётная запись
    users: [], //учётные записи на этом приложении
    isAuth: false,
    tokenAuth: '',
    authStatus: '', // например идёт ожидангие авторизациия, ошибка авторизациия
    count: 0,  //пример
    //   inputMessage: { text: '' },
    // interface: { isVisibleModal: false, isVisibleOffcanvas: false, isFreeBlockForMessage: false, isVisibleModalDebug: false} //модальное isVisibleModal
});

// _ ФУНКЦИЯ ЗАГРУЗКИ СОСТОЯНИЯ localStorage
function _loadState() {
    try {
        const serializedState = localStorage.getItem(serviceName);
        return serializedState === null ? undefined : JSON.parse(serializedState);
    } catch (err) {
        log.error('Ошибка загрузки состояния в localStorage:', err)
        return undefined;
    }
}

// _ ФУНКЦИЯ СОХРАНЕНИЕ СОСТОЯНИЯ
// function _saveState() {
//     try {
//         const serializedState = JSON.stringify({});
//         localStorage.setItem(serviceName, serializedState);
//     } catch (err) {
//         log.error('Ошибка сохранения состояния в localStorage:', err)
//     }
// }

// ИНИЦИАЛИЗАЦИЯ ПРИ СОЗДАНИИ СЕРВИСА
function initializationCreate() {
    log.info('initializationCreate()')
    // проверяем, как надо первоначально загрузить из файла или из хранилища
    if (_loadState() == null) {
        log.info('данные отсутствуют в хранилище')
    }
    else {
        log.info('данные присутствуют в хранилище loadState()', _loadState());
    }
}
initializationCreate()

// определяем композитную функцию для управления состоянием
export function authService() {

    // GET-STATE Геттер для получения текущего состояния
    const getCurrentUser = computed(() => state.value.User);  //пример
    // const getIsAuth = computed(() => state.value.isAuth);  //пример
    const isAuth = computed(() => state.value.isAuth);  //пример
    const getCount = computed(() => state.value.count);  //пример
    const getAuthStatus = computed(() => state.value.authStatus);  //пример
    const getTokenAuth = computed(() => state.value.tokenAuth);  //пример

    


    //   const getCurrentChat = computed(() => state.value.chats.find(item => item.id === state.value.currentChatID));
    //   const getCurrentMessageIndex = computed(() => getCurrentChat.value.messages.findIndex((message: any) => message.id === getCurrentMessageID.value));

    // const fetchAuth = async (username: string, password: string, remember: boolean) => {
        
    //     log.info('+fetchAuth', [username, password, remember])
    //     state.value.authStatus = AuthMode.AwaitAuth;

    //     const result = await api.request('login.authAPI',{           
    //             username: username,
    //             password: password,
    //             remember: remember
    //     })
    //     if (result.success) {        
    //         log.warning("Успешно аутентифицирован:", result.data);
    //         // токен присваиваю
    //         state.value.User = result.data.user
    //         state.value.tokenAuth = result.data.token
    //         state.value.isAuth = true
    //         state.value.authStatus = AuthMode.BeginState
    //         return {'success':true}

    //     }else{
    //         log.warning("Отказано в аутентификации:", result);
    //         state.value.isAuth = false
    //         state.value.authStatus = AuthMode.BeginState
    //         return {'success':false}
    //     }       
        

    //     // await fetch(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_LOGIN}`, {
    //     //     method: 'POST',
    //     //     headers: {
    //     //       'Content-Type': 'application/json'
    //     //     },
    //     //     body: JSON.stringify({
    //     //         username: username,
    //     //         password: password,
    //     //         remember: remember
    //     //     })
    //     //   })
    //     //     .then(response => response.json())
    //     //     .then(data => {
    //     //         log.info('пришел ответ fetchAuth', data)
    //     //         if (data.success) {
    //     //             log.warning("Успешно аутентифицирован:", data.token);
    //     //             // токен присваиваю
    //     //             state.value.tokenAuth = data.token
    //     //             state.value.isAuth = true
    //     //         }
    //     //         else {
    //     //             log.warning("Отказано в аутентификации:", data);
    //     //             state.value.isAuth = false
    //     //         }
    //     //     })
    //     //     .catch(error => {
    //     //         log.error('ошибка запроса fetchAuth:', error+'');
    //     //         state.value.isAuth = false
    //     //     })
    //     //     .finally(() => {state.value.authStatus = AuthMode.BeginState});
    // };
    //ВХОД
    const login = async (username: string, password: string, remember: boolean) => {
        log.warning('+login попытка входа .. login', [username, password, remember])
        // const result = await fetchAuth(username, password, remember)
      
        state.value.authStatus = AuthMode.AwaitAuth;

        const result = await api.request('login.authAPI',{           
                username: username,
                password: password,
                remember: remember
        })
        if (result.success) {        
            log.warning("Успешно аутентифицирован:", result.data);
            // токен присваиваю
            state.value.User = result.data.user
            state.value.tokenAuth = result.data.token
            state.value.isAuth = true
            state.value.authStatus = AuthMode.BeginState
            return {'success':true}

        }else{
            log.warning("Отказано в аутентификации:", result);
            state.value.isAuth = false
            state.value.authStatus = AuthMode.BeginState
            return {'success':false}
        }  

    };

    //ВЫХОД
    const logout = async () => {  

        if(state.value.User!=null){
            log.warning('Кто-то ВЫХОДИТ .. login', state.value.User)
        }       
        const result = await api.request('login.logoutAPI')

        if (result.success) {        
            log.info("успешно вышел:", result.data);
            // пробуем авторизацияю
            state.value.authStatus = AuthMode.BeginState;
            state.value.User = null;
            state.value.isAuth = false;         
            return {'success':true}
        }else{
            log.error("НЕ успешно вышел:", result.data);
            return {'success':false}
        } 



        

        // _auth(username, password, remember).then(success => {
        //     // Здесь можно обработать успешный результат            
        //     if (success) {

        //         log.warning("Успешно аутентифицирован:", success);
        //         state.value.isAuth = true

        //     }
        //     else {
        //         log.warning("Отказано в аутентифицировании:", success);
        //         state.value.isAuth = false
        //     }
        // }).catch(error => {
        //     log.error("Ошибка аутентификации ERROR:", error);
        // }).finally(() => {
        //     state.value.authStatus = AuthMode.BeginState;
        //     // выполняется в любом случае
        //     console.log('Завершено.');
        // });
        // console.log(auth)
        // пролетает махом
        // log.info('logout заверешила выполнение')
    };



    // КОНТРОЛЛЕР УПРАВЛЕНИЯ ИНТЕРФЕЙСОМ
    // const setInterface = (key: string, toggle: boolean) => {
    //     state.value.interface[key] = toggle;

    //     // ПРИМЕР enable
    //     switch (key) {
    //         case 'isVisibleModal':
    //             // enable
    //             if (toggle) {
    //                 return;
    //             } // disable
    //             else {
    //                 //   pass
    //             }
    //             break;
    //         default:
    //             break;
    //     }

    // };

    // Возвращаем геттеры и действия
    return {
        getTokenAuth,
        getCurrentUser,
        // getIsAuth,
        getAuthStatus,
        isAuth,
        getCount,
        // setInterface,
        login,
        logout
    };
}

