<!-- на меню кнопка отвечающая за переходы -->
<template>

    <!-- MENU -->
    <!-- <MenuItem @click="router.push({ path: `/main/welcome` })" path="/main/welcome" name="ГЛАВНАЯ" description="">
    <template #default>
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-house"
            viewBox="0 0 16 16">
            <path
                d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5z" />
        </svg>
    </template>
</MenuItem> -->

    <MenuItem v-if="isAuth" @click="router.push({ path: `/main/home` });" path="/main/home" name="Профиль"
        description="">
    <template #default>
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
            class="bi bi-person-square opacity-70" viewBox="0 0 16 16">
            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
            <path
                d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1v-1c0-1-1-4-6-4s-6 3-6 4v1a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
        </svg>
    </template>
    </MenuItem>

    <MenuItem v-if="!isAuth" @click="router.push({ path: `/main/login` })" path="/main/login" name="ВХОД"
        description="">
    <template #default>
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-person"
            viewBox="0 0 16 16">
            <path
                d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
        </svg>
    </template>
    </MenuItem>

    <!-- <MenuItem name="Сообщения" description="" @click="router.push({ path: `/chat/main` });" path="/chat/main">
    <template #default>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chat-dots"
            viewBox="0 0 16 16">
            <path
                d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
            <path
                d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9 9 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.4 10.4 0 0 1-.524 2.318l-.003.011a11 11 0 0 1-.244.637c-.079.186.074.394.273.362a22 22 0 0 0 .693-.125m.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6-3.004 6-7 6a8 8 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a11 11 0 0 0 .398-2" />
        </svg>
    </template>
    </MenuItem> -->

    <MenuItem v-if="isAuth" name="DEBUG" description="" @click="router.push({ path: `/main/debug` });" path="/main/debug">
    <template #default>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-code-square"
            viewBox="0 0 16 16">
            <path
                d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
            <path
                d="M6.854 4.646a.5.5 0 0 1 0 .708L4.207 8l2.647 2.646a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 0 1 .708 0m2.292 0a.5.5 0 0 0 0 .708L11.793 8l-2.647 2.646a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708 0" />
        </svg>
    </template>
    </MenuItem>


</template>
<script setup lang="ts">
import { useRouter } from 'vue-router'
// import { useRoute } from 'vue-router'
const router = useRouter();
// const route = useRoute();

// import { ref, Ref } from 'vue';
// import { defineProps } from 'vue';
import MenuItem from "@/components/items/MenuItem.vue";

import { authService } from '@/service/authService';
const { isAuth } = authService(); // login

// Определяем и типизируем пропсы компонента
// const props = defineProps<{
//     name: {
//         type:string,
//         default:''
//     };
//     description: {
//         type:string,
//         default:''
//     };
// }>();
// const props = defineProps<{
//     name?: string;
//     description?: string;
// }>();
// const defaultProps = {
//   name: '',
//   description: '',
// };
// определяем переменные
// const isHovered: Ref<boolean> = ref(false)
// const hoverStyle = () => {
//     return {
//         transition: 'opacity 0.5s',
//         opacity: isHovered.value ? 1 : 0.8,

//     };
// }

// document.querySelectorAll('.ripple').forEach(button => {
//         button.addEventListener('click', function (e) {
//             const rect = this.getBoundingClientRect();
//             const ripple = document.createElement('span');

//             const diameter = Math.max(rect.width, rect.height);
//             const radius = diameter / 2;

//             ripple.style.width = ripple.style.height = `${diameter}px`;
//             ripple.style.left = `${e.clientX - rect.left - radius}px`;
//             ripple.style.top = `${e.clientY - rect.top - radius}px`;

//             ripple.classList.add('ripple-effect');
//             this.appendChild(ripple);

//             setTimeout(() => {
//                 ripple.remove();
//             }, 600);
//         });
//     });
</script>
<style scoped lang="scss"></style>