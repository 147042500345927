// layoutService - отвечает за функционирование области чата

// LAYOUT-SERVICE
// import { layoutService } from '@/service/layoutService';
// const { } = layoutService();

//LOG-SERVICE

const serviceName = 'layoutService'

import {LogService} from '@/service/logService';
const log = new LogService(serviceName);
// VUE
import { Ref,ref, computed} from 'vue';

// ROUTE
// import { useRouter } from 'vue-router'
// import { useRoute } from 'vue-router'
// const router = useRouter();

// STATE
// interface State {
//   count: number;
//   chats: Array<any>
//   currentChatID: number,
//   currentMessageID: number,
//   currentMessageIndex: number,
//   interface: any,
//   currentMessageMode: string,
//   inputMessage: { text: string }
// }
interface State {
  interface: {
    isVisibleModalItem: boolean;
    isVisibleOffcanvasBlockDefault: boolean;
  };
}

// CREATE-STATE
const state: Ref<State> = ref({
  interface: {
    isVisibleModalItem: false,
    isVisibleOffcanvasBlockDefault: false
  }
});

// _ ФУНКЦИЯ ЗАГРУЗКИ СОСТОЯНИЯ localStorage
function _loadState(key: string) {
  try {
    const serializedState = localStorage.getItem(key);
    return serializedState === null ? undefined : JSON.parse(serializedState);
  } catch (err) {
    log.error('Ошибка загрузки состояния в localStorage:', err)
    return undefined;
  }
}

// _ ФУНКЦИЯ СОХРАНЕНИЕ СОСТОЯНИЯ
// function _saveState(key: string, value: any) {
//   try {
//     const serializedState = JSON.stringify(value);
//     localStorage.setItem(key, serializedState);
//   } catch (err) {
//     log.error('Ошибка сохранения состояния в localStorage:', err)
//   }
// }

// ИНИЦИАЛИЗАЦИЯ ПРИ СОЗДАНИИ СЕРВИСА
function initializationCreate() {
  log.info('initializationCreate()')
  // проверяем, как надо первоначально загрузить из файла или из хранилища
  if (_loadState('layout') == null) {
    log.info('- данные отсутствуют в хранилище')   
  }
  else {
    log.info('- данные присутствуют в хранилище loadState()', _loadState('layout'));
  }
}
initializationCreate()

// определяем композитную функцию для управления состоянием
export function layoutService() {

  // GET-STATE Геттер для получения текущего состояния
  // const getCount = computed(() => state.value.count);  //пример
  // const getChats = computed(() => state.value.chats);
  // const getCurrentChatID = computed(() => state.value.currentChatID);
  // const getCurrentMessageID = computed(() => state.value.currentMessageID);
  // const getCurrentChat = computed(() => state.value.chats.find(item => item.id === state.value.currentChatID));
  // const getCurrentMessageIndex = computed(() => getCurrentChat.value.messages.findIndex((message: any) => message.id === getCurrentMessageID.value));
  // const getCurrentMessage = computed(() => getCurrentChat.value.messages[getCurrentMessageIndex.value]);
  // // state.value.chats[state.value.currentChatID].messages.findIndex((message: any) => message.id === getCurrentMessageID.value))
  // const getCurrentMessageMode = computed(() => state.value.currentMessageMode);
  const getInterfaceState = computed(() => state.value.interface);
  // const getInputMessage = computed(() => state.value.inputMessage);

  //УПРАВЛЕНИЕ ИНТЕРФЕЙСОМ -  ПРИ ПЕРЕХОДЕ НА ДРУГОЙ АКТИВНЫЙ ЧАТ
  const toggleModalItem = () => {
    getInterfaceState.value.isVisibleModalItem=!getInterfaceState.value.isVisibleModalItem
    // state.value.currentMessageID = id;
    // state.value.currentMessageMode = '';
    // state.value.inputMessage.text = ''
    
  };

  const toggleOffcanvasBlockDefault = () => {
    getInterfaceState.value.isVisibleOffcanvasBlockDefault=!getInterfaceState.value.isVisibleOffcanvasBlockDefault
    // state.value.currentMessageID = id;
    // state.value.currentMessageMode = '';
    // state.value.inputMessage.text = ''
    
  };







  // Возвращаем геттеры и действия
  return {
    getInterfaceState,
    toggleModalItem,
    toggleOffcanvasBlockDefault,
   

  };
}



log.info('Конец создания chatService')