/* eslint-disable */
// logService - отвечает за логирование приложения
// Пример использования
// import {LogService} from '@/service/logService';
// const log = new LogService('index.ts');
// log.info('+route')

// const context = {
//     userId: 12345,
//     operation: "user_login",
//     ipAddress: "192.168.1.1"
// };
// Logger.info("Пользователь вошёл в систему", context);

// const error = {
//     name: "TypeError",
//     message: "Ошибка при выполнении операции",
//     stack: "stack trace if applicable"
// };

// Logger.error("Произошла ошибка при входе пользователя", context, error);

// import { debug } from "console";
// import { api } from '@/router/fetchAPI';
enum LogLevel {
    Info = "info",
    Warning = "warning",
    Error = "error",
    Critical = "critical"
}

// interface LogContext {
//     userId?: number;
//     operation?: string;
//     ipAddress?: string;
//     // дополнительные поля можно добавлять по мере необходимости
//     // [key: string]: string | number;
// }

interface LogError {
    name: string;
    message: string;
    stack?: string;
}

interface LogEntry {
    timestamp: string;
    level: LogLevel;
    message: string;
    context?: any;
    error?: LogError;
    source?: string;
}

interface LogPage {
    name: string;
    debug: boolean;
}

export class LogService {
    
    source: string;
    static pages: Array<LogPage> = [
        // page
        { name: 'MobileFooterItem', debug: false },
        { name: 'ChatPageLayout', debug: false },
        { name: 'MessageList', debug: false },
        { name: 'ThemeButton', debug: false },
        { name: 'fetchAPI', debug: false },
        
        // service
        { name: 'main', debug: false },
        { name: 'index', debug: false },
        { name: 'chatAPI', debug: false },        
        { name: 'chatService', debug: true },
        { name: 'layoutService', debug: false },
        { name: 'authService', debug: true },
        { name: 'centrifugeService', debug: true }, 
    ];


    constructor(source: string) {
        this.source = source;
        // console.log(this.pages)
    }

    static log(source: string, level: LogLevel, message: string, context?: any, error?: LogError): void {

        const logEntry: LogEntry = {
            source,
            message,
            context,
          
            error,
            timestamp: new Date().toISOString(),
            level,
        };

       

        const page = this.pages.find((element: LogPage) => element.name === source);

        const forConsoleLogEntry: any = logEntry        
        // forConsoleLogEntry.txt = forConsoleLogEntry.message;  
        const updateConsoleLogEntry:any = {};
        const oldKey:any = 'message';
        const newKey:any = 'txt'

        // Перебираем все ключи объекта
        for (const key in forConsoleLogEntry) {
            // Если совпадает с тем ключом, который нужно поменять
            // используем новое имя ключа
            if (key === oldKey) {
                updateConsoleLogEntry[newKey] = forConsoleLogEntry[key];
            } else {
                updateConsoleLogEntry[key] = forConsoleLogEntry[key];
            }
        }      
        
        
        delete updateConsoleLogEntry.error;
        delete updateConsoleLogEntry.timestamp;
        delete updateConsoleLogEntry.level;

        if (page) {
            if (page.debug) {
                // console.log(JSON.stringify(forConsoleLogEntry));
                console.log(updateConsoleLogEntry);                
            }
        }
        else {
            console.log('NOT PROGRAMMING');
            console.log(updateConsoleLogEntry)
            // console.log(JSON.stringify(forConsoleLogEntry));
        }


    }

    public info(message: string, context?: any): void {
        LogService.log(this.source, LogLevel.Info, message, context); 
        
        

    }

    public warning(message: string, context?: any): void {
        LogService.log(this.source, LogLevel.Warning, message, context);
    }

    public error(message: string, context?: any, error?: LogError): void {
        LogService.log(this.source, LogLevel.Error, message, context, error);
    }

    //те которые отлежены после создания
    public critical(message: string, context?: any, error?: LogError): void {
        LogService.log(this.source, LogLevel.Critical, message, context, error);
    }
}

