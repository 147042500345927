<template>
  <!-- Мобильный низ -->



  <footer
    class="flex z-[30] h-16 flex-row fixed left-0 bottom-0 w-full bg-mobile-footer-light dark:bg-mobile-footer-dark">


    <!-- ЭТО ЕСЬ БЛОК - ВРЕМЕМЕННЫЙ ОСТУП -->
    <div class="flex w-full h-full items-center pl-0 sm:pl-72 xl:mx-auto xl:container gap-x-2">


      <!-- ПК КОНТЕНТ -->
      <div class="hidden sm:flex justify-between relative w-full ps-2 pt-0">
        <span class="font-bold text-lg text-white">2025</span>
      </div>

      <!-- МОБИЛЬНЫЙ КОНТЕНТ -->
      <div class="flex sm:hidden justify-between relative w-full ps-2 pt-0">
        <MobileFooterItem />
      </div>


    </div>

  </footer>



  <!-- <footer class="flex w-full z-[20] flex sticky bottom-0 bg-mobile-footer-light dark:bg-mobile-footer-dark p-4 md:hidden">
    <div class="container mx-auto">

      <div class="flex justify-between">
        <MobileFooterItem/>
      
      </div>
     
    </div>
  </footer> -->



  <!-- <footer class="block md:hidden sticky bottom-0 bg-mobile-footer-light dark:bg-mobile-footer-dark px-2 z-40">
    <div class="flex 2xl:container mx-auto text-center">

      <div class="flex justify-between w-full p-2">

    fff

      </div>
    
    </div>
  </footer> -->
</template>
<script setup lang="ts">
// import { useRouter } from 'vue-router'
import { useRoute } from 'vue-router'
import { ref, Ref, onMounted, onBeforeUnmount, watch } from 'vue';
import MobileFooterItem from "@/components/items/MobileFooterItem.vue";
// определяем переменные
// const router = useRouter();
const route = useRoute();

// название маршрута
const routeName: Ref<string> = ref('')


// Наблюдатель, следящий за изменениями `count`
// newVal, oldVal
watch(routeName, () => {

  // newVal
  // console.log(`routeName изменен с ${oldVal} на ${newVal}`);
});
// Создание вычисляемого свойства
// const newRouteName = computed(() => routeName.value + '---');

// Действия при монтировании компонента
onMounted(() => {
  routeName.value = route.name as string;
  // console.log('Компонент MobileFooterBlock смонтирован');
});
// Действия при размонтировании компонента
onBeforeUnmount(() => {
  // console.log('Компонент MobileFooterBlock будет размонтирован');
});

</script>
<style scoped></style>