import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref } from 'vue';
// import ModalItem from './parts/ThemeButton/ModalItem.vue';
import BtnBlock from '@/components/blocks/BtnBlock.vue';


import {LogService} from '@/service/logService';

export default /*@__PURE__*/_defineComponent({
  __name: 'ThemeButton',
  setup(__props) {

// VUE
const log = new LogService('ThemeButton');
const themeName = ref('light')

// const visibleModal = ref(false)
// function toggleModal() {
//   visibleModal.value = !visibleModal.value
// }

// _ ФУНКЦИЯ ЗАГРУЗКИ СОСТОЯНИЯ localStorage
function loadState(key: string) {
  try {
    const serializedState = localStorage.getItem(key);
    return serializedState === null ? undefined : JSON.parse(serializedState);
  } catch (err) {
    log.error('Ошибка загрузки состояния в localStorage:', err)
    return undefined;
  }
}

// _ ФУНКЦИЯ СОХРАНЕНИЕ СОСТОЯНИЯ
function saveState(key: string, value: string) {
  try {
    const serializedState = JSON.stringify(value);
    localStorage.setItem(key, serializedState);
  } catch (err) {
    log.error('Ошибка сохранения состояния в localStorage:', err)
  }
}

function initializeTheme() {
  log.info('initializeTheme()')
  log.info('В браузере тёмная тема? matchMedia-dark', window.matchMedia("(prefers-color-scheme: dark)").matches)
  log.info('В браузере светлая тема? matchMedia-light', window.matchMedia("(prefers-color-scheme: light)").matches)





  try {
    const html = document.querySelector("html");
    if (html != null) {

      if (loadState('theme') != null) {
        // есть тема в хранилище
        themeName.value = loadState('theme')
        html.classList.remove("light");
        html.classList.remove("dark");
        html.classList.add(loadState('theme'))
      }
      else {
        // проверяю в браузере
        // нет темы в хранилище
        const isThemeDarkWebView = window.matchMedia("(prefers-color-scheme: dark)").matches;
        // если сейчас тёмная тема
        if (isThemeDarkWebView) {
          themeName.value = 'dark'
          html.classList.remove("light");
          html.classList.add("dark")
        } else {
          themeName.value = 'light'
          html.classList.remove("dark");
          html.classList.add("light")
        }
      }



      saveState('theme', themeName.value)
    }
    else {
      log.error('не найден html')
    }
  } catch (error) {
    log.error('ошибка при выполненении initializeTheme()', error + '')
  }

  // const isLightOrAuto =
  //   localStorage.getItem("hs_theme") === "light" ||
  //   (localStorage.getItem("hs_theme") === "auto" &&
  //     !window.matchMedia("(prefers-color-scheme: dark)").matches);
  // const isDarkOrAuto =
  //   localStorage.getItem("hs_theme") === "dark" ||
  //   (localStorage.getItem("hs_theme") === "auto" &&
  //     window.matchMedia("(prefers-color-scheme: dark)").matches);
  //   if (isLightOrAuto && html.classList.contains("dark"))
  //     html.classList.remove("dark");
  //   else if (isDarkOrAuto && html.classList.contains("light"))
  //     html.classList.remove("light");
  //   else if (isDarkOrAuto && !html.classList.contains("dark"))
  //     html.classList.add("dark");
  //   else if (isLightOrAuto && !html.classList.contains("light"))
  //     html.classList.add("light");
}


function changeTheme() {
  log.info('changeTheme()')
  const html = document.querySelector("html");
  if (html != null) {
    switch (themeName.value) {

      case 'light':
        themeName.value = 'dark'
        html.classList.remove("light");
        html.classList.add("dark")
        saveState('theme', themeName.value)
        break;
      case 'dark':
        themeName.value = 'light'
        html.classList.remove("dark");
        html.classList.add("light")
        saveState('theme', themeName.value)
        break;
      default:
        log.error('не определена themeName.value')
        break;
    }
  }



}
initializeTheme()

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BtnBlock, {
    level: "light",
    icon: themeName.value,
    size: "small",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (changeTheme()))
  }, null, 8, ["icon"]))
}
}

})