<template>


  <!-- <ModalItem :show="visibleModal" :closeable="false" @close="toggleModal()">
    <div class=" p-4 space-y-3 pb-2 ">
      <button id="theme-toggle" @click="changeTheme()"
        class="hs-dark-mode-active:hidden block border border-blue-600/10 hover:border-blue-600 text-gray font-bold py-2 px-4 rounded shadow-md transform transition-all duration-200 active:scale-95 active:shadow-lg">
        <svg class="shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24"
          fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <path d="M12 3a6 6 0 0 0 9 9 9 9 0 1 1-9-9Z"></path>
        </svg>
      </button>

      <button @click="changeTheme()"
        class="hs-dark-mode-active:block hidden border border-blue-600/10 hover:border-blue-600 text-gray font-bold py-2 px-4 rounded shadow-md transform transition-all duration-200 active:scale-95 active:shadow-lg">
        <svg class="shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24"
          fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <circle cx="12" cy="12" r="4"></circle>
          <path d="M12 2v2"></path>
          <path d="M12 20v2"></path>
          <path d="m4.93 4.93 1.41 1.41"></path>
          <path d="m17.66 17.66 1.41 1.41"></path>
          <path d="M2 12h2"></path>
          <path d="M20 12h2"></path>
          <path d="m6.34 17.66-1.41 1.41"></path>
          <path d="m19.07 4.93-1.41 1.41"></path>
        </svg>
      </button>
    </div>
  </ModalItem> -->

  <BtnBlock level="light" :icon="themeName" size="small" @click="changeTheme()"></BtnBlock>


</template>

<script setup lang="ts">
// VUE
import { ref } from 'vue';
// import ModalItem from './parts/ThemeButton/ModalItem.vue';
import BtnBlock from '@/components/blocks/BtnBlock.vue';


import {LogService} from '@/service/logService';
const log = new LogService('ThemeButton');
const themeName = ref('light')

// const visibleModal = ref(false)
// function toggleModal() {
//   visibleModal.value = !visibleModal.value
// }

// _ ФУНКЦИЯ ЗАГРУЗКИ СОСТОЯНИЯ localStorage
function loadState(key: string) {
  try {
    const serializedState = localStorage.getItem(key);
    return serializedState === null ? undefined : JSON.parse(serializedState);
  } catch (err) {
    log.error('Ошибка загрузки состояния в localStorage:', err)
    return undefined;
  }
}

// _ ФУНКЦИЯ СОХРАНЕНИЕ СОСТОЯНИЯ
function saveState(key: string, value: string) {
  try {
    const serializedState = JSON.stringify(value);
    localStorage.setItem(key, serializedState);
  } catch (err) {
    log.error('Ошибка сохранения состояния в localStorage:', err)
  }
}

function initializeTheme() {
  log.info('initializeTheme()')
  log.info('В браузере тёмная тема? matchMedia-dark', window.matchMedia("(prefers-color-scheme: dark)").matches)
  log.info('В браузере светлая тема? matchMedia-light', window.matchMedia("(prefers-color-scheme: light)").matches)





  try {
    const html = document.querySelector("html");
    if (html != null) {

      if (loadState('theme') != null) {
        // есть тема в хранилище
        themeName.value = loadState('theme')
        html.classList.remove("light");
        html.classList.remove("dark");
        html.classList.add(loadState('theme'))
      }
      else {
        // проверяю в браузере
        // нет темы в хранилище
        const isThemeDarkWebView = window.matchMedia("(prefers-color-scheme: dark)").matches;
        // если сейчас тёмная тема
        if (isThemeDarkWebView) {
          themeName.value = 'dark'
          html.classList.remove("light");
          html.classList.add("dark")
        } else {
          themeName.value = 'light'
          html.classList.remove("dark");
          html.classList.add("light")
        }
      }



      saveState('theme', themeName.value)
    }
    else {
      log.error('не найден html')
    }
  } catch (error) {
    log.error('ошибка при выполненении initializeTheme()', error + '')
  }

  // const isLightOrAuto =
  //   localStorage.getItem("hs_theme") === "light" ||
  //   (localStorage.getItem("hs_theme") === "auto" &&
  //     !window.matchMedia("(prefers-color-scheme: dark)").matches);
  // const isDarkOrAuto =
  //   localStorage.getItem("hs_theme") === "dark" ||
  //   (localStorage.getItem("hs_theme") === "auto" &&
  //     window.matchMedia("(prefers-color-scheme: dark)").matches);
  //   if (isLightOrAuto && html.classList.contains("dark"))
  //     html.classList.remove("dark");
  //   else if (isDarkOrAuto && html.classList.contains("light"))
  //     html.classList.remove("light");
  //   else if (isDarkOrAuto && !html.classList.contains("dark"))
  //     html.classList.add("dark");
  //   else if (isLightOrAuto && !html.classList.contains("light"))
  //     html.classList.add("light");
}


function changeTheme() {
  log.info('changeTheme()')
  const html = document.querySelector("html");
  if (html != null) {
    switch (themeName.value) {

      case 'light':
        themeName.value = 'dark'
        html.classList.remove("light");
        html.classList.add("dark")
        saveState('theme', themeName.value)
        break;
      case 'dark':
        themeName.value = 'light'
        html.classList.remove("dark");
        html.classList.add("light")
        saveState('theme', themeName.value)
        break;
      default:
        log.error('не определена themeName.value')
        break;
    }
  }



}
initializeTheme()
</script>
<style scoped lang="scss"></style>
