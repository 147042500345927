import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

import { ref, computed } from "vue";
  
  // import { defineExpose } from 'vue';

// import { defineProps } from "vue";
 // eslint-disable-next-line
interface Notification {
    id: number;
    message: string;
    duration: number; // время в миллисекундах для удаления уведомления
  }
  
  // type Position = 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left';
  
  // Reactive список уведомлений
  
export default /*@__PURE__*/_defineComponent({
  __name: 'NotificationItem',
  props: {

        items: {
            type: Array,
            default: () => [],
        },

        propsPosition: {
            type: String,
            default: 'top-right',
        },

        propsDefaultDuration: {
            type: Number,
            default: 3000,
        },

},
  setup(__props, { expose: __expose }) {

  const props = __props;



  const items = ref<Notification[]>([]);
  
  // Параметры можно передавать как props (или настроить по умолчанию)
  // const propsPosition = ref<Position>('top-right'); 
  // const propsDefaultDuration = ref<number>(3000); // Уведомление исчезает через 3000 мс
  
  // Добавление уведомления
  function addNotification(message: string, duration?: number) {
    const id = Date.now(); // Уникальный id по текущему времени
    items.value.push({
      id,
      message,
      duration: duration ?? props.propsDefaultDuration,
    });
  
    // Удаляем уведомление через `duration` мс
    setTimeout(() => {
      removeNotification(id);
    }, duration ?? props.propsDefaultDuration);
  }
  
  // Удаление уведомления по id
  function removeNotification(id: number) {
    items.value = items.value.filter(n => n.id !== id);
  }
  
  // Позиция уведомления
  const position = computed(() => {
    switch (props.propsPosition) {
      case 'top-right':
        return 'top-right';
      case 'top-left':
        return 'top-left';
      case 'bottom-right':
        return 'bottom-right';
      case 'bottom-left':
        return 'bottom-left';
      default:
        return 'top-right';
    }
  });
  
  // Экспортируем методы для добавления уведомления (его можно вызывать из других компонентов)
  // export { addNotification };

   // eslint-disable-next-line
  __expose({
    addNotification
  });
  
return (_ctx: any,_cache: any) => {
  return (items.value.length)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(['notification-list', position.value])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items.value, (notification) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "notification backdrop-blur-md bg-black/60",
            key: notification.id
          }, _toDisplayString(notification.message), 1))
        }), 128))
      ], 2))
    : _createCommentVNode("", true)
}
}

})