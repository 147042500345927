import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "py-3 px-2" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "font-bold uppercase" }

import { ref, Ref } from 'vue';
// import { defineProps } from 'vue';
// import { useRouter } from 'vue-router'
import { useRoute } from 'vue-router'
// const router = useRouter();

export default /*@__PURE__*/_defineComponent({
  __name: 'MenuItem',
  props: {
    name: {},
    description: {},
    path: {}
  },
  setup(__props: any) {

const route = useRoute();

// Определяем и типизируем пропсы компонента
// const props = defineProps<{
//     name: {
//         type:string,
//         default:''
//     };
//     description: {
//         type:string,
//         default:''
//     };
// }>();
 // eslint-disable-next-line
const props = __props;
// const defaultProps = {
//   name: '',
//   description: '',
// };
// определяем переменные
const isHovered: Ref<boolean> = ref(false)
const hoverStyle = () => {
    return {
        transition: 'opacity 0.5s',
        opacity: isHovered.value ? 1 : 0.8,

    };
}

// document.querySelectorAll('.ripple').forEach(button => {
//         button.addEventListener('click', function (e) {
//             const rect = this.getBoundingClientRect();
//             const ripple = document.createElement('span');

//             const diameter = Math.max(rect.width, rect.height);
//             const radius = diameter / 2;

//             ripple.style.width = ripple.style.height = `${diameter}px`;
//             ripple.style.left = `${e.clientX - rect.left - radius}px`;
//             ripple.style.top = `${e.clientY - rect.top - radius}px`;

//             ripple.classList.add('ripple-effect');
//             this.appendChild(ripple);

//             setTimeout(() => {
//                 ripple.remove();
//             }, 600);
//         });
//     });

return (_ctx: any,_cache: any) => {
  const _directive_ripple = _resolveDirective("ripple")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    onMouseenter: _cache[0] || (_cache[0] = ($event: any) => (isHovered.value = true)),
    onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (isHovered.value = false)),
    style: _normalizeStyle(hoverStyle()),
    class: _normalizeClass([[props.path == _unref(route).path ? ' bg-white/20 dark:bg-black/30' : ''], "relative overflow-hidden flex flex-row text-md select-none cursor-pointer border border-white/10 dark:bg-body-dark rounded transform transition duration-300 ease-in-out hover:shadow-md focus:outline-none"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "name"),
        _createTextVNode(" " + _toDisplayString(props.name), 1)
      ])
    ])
  ], 38)), [
    [_directive_ripple]
  ])
}
}

})