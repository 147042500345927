import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, withCtx as _withCtx, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col h-screen xl:container mx-auto" }
const _hoisted_2 = { class: "p-4 space-y-3 pb-2" }
const _hoisted_3 = { class: "flex flex-1 pt-16" }
const _hoisted_4 = { class: "pt-4 max-h-[calc(100vh-5rem)] min-h-[calc(100vh-8rem)] overflow-y-auto hidden sm:block w-72 flex-none p-2 pb-16 fixed inset-y-06 space-y-2 bg-menu-light dark:bg-menu-dark" }
const _hoisted_5 = { class: "overflow-y-auto max-h-[calc(100vh-8rem)] p-2 pb-4 relative flex-1 ml-0 sm:ml-72 bg-chat-page-body-light dark:bg-chat-page-body-dark" }
const _hoisted_6 = { class: "flex flex-col gap-y-2" }

import MobileFooterBlock from "@/components/blocks/MobileFooterBlock.vue";
import HeaderBlock from "@/components/blocks/HeaderBlock.vue";
// import MenuItem from "@/components/items/MenuItem.vue";
// import OffcanvasBlock from '@/components/blocks/OffcanvasBlock.vue';
import MenuListDefault from "@/components/items/menu/MenuListDefault.vue";
import OffcanvasBlockDefault from "@/components/blocks/OffcanvasBlockDefault.vue";
import ModalItem from "@/components/blocks/ModalItem.vue";

// LAYOUT-SERVICE
import { layoutService } from '@/service/layoutService';
import NotificationItem from '@/components/items/NotificationItem.vue';
import { ref } from 'vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'AuthenticatedPageLayout',
  setup(__props) {


// СТАРЫЕ КОМПОНЕНТЫ - РЕВИЗИЯ
const { toggleModalItem, getInterfaceState, toggleOffcanvasBlockDefault } = layoutService();

const notification = ref()
// function notify() {
//   notification.value.addNotification("Новое уведомление!", 5000)
//   console.log(notification.value)
//   // notification.value.addNotification("Новое уведомление!", 5000)
//   // addNotification("Новое уведомление!", 5000); // Уведомление будет удалено через 5 секунд
// }
// import { centrifugeService } from '@/service/centrifugeService';
// const { centrifugeConnect } = centrifugeService();
// centrifugeConnect()

// // тестирование
// import { chatAPI } from '@/service/chatAPI';
// const { getChatListAPI } = chatAPI();
// getChatListAPI()

// Allocate Subscription to a channel.
// const sub = centrifuge.newSubscription('public_channel');

// // React on `news` channel real-time publications.
// sub.on('publication', function(ctx:any) {
//     console.log('publication',ctx.data);
// });

// // Trigger subscribe process.
// sub.subscribe();
// COMPONENTS
// import MessageItem from "@/components/items/chat/parts/MessageItem.vue";
// import MobileFooterBlock from "@/components/items/chat/parts/MobileFooterBlock.vue";
// import HeaderBlock from "@/components/items/chat/parts/HeaderBlock.vue";
// import OffcanvasBlock from '@/components/items/chat/parts/OffcanvasBlock.vue';
// import MenuListDefault from "@/components/items/menu/MenuListDefault.vue";
// import DialogItem from "@/components/items/chat/parts/DialogItem.vue";
// import DialogList from "@/components/items/chat/parts/DialogList.vue";
// import ModalBlock from "@/components/items/chat/parts/ModalBlock.vue";
// import DropdownItem from "@/components/items/chat/parts/DropdownItem.vue";
// import ModalItem from "@/components/items/chat/parts/ModalItem.vue";
// import OffcanvasBlockDefault from "@/components/items/chat/parts/OffcanvasBlockDefault.vue";


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(HeaderBlock),
    _createVNode(OffcanvasBlockDefault, {
      show: _unref(getInterfaceState).isVisibleOffcanvasBlockDefault,
      onClose: _cache[0] || (_cache[0] = ($event: any) => (_unref(toggleOffcanvasBlockDefault)())),
      closeable: false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(MenuListDefault)
        ])
      ]),
      _: 1
    }, 8, ["show"]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("aside", _hoisted_4, [
        _createVNode(MenuListDefault)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(NotificationItem, {
            ref_key: "notification",
            ref: notification,
            propsPosition: "bottom-right"
          }, null, 512),
          _createVNode(ModalItem, {
            show: _unref(getInterfaceState).isVisibleModalItem,
            closeable: false,
            onClose: _cache[1] || (_cache[1] = ($event: any) => (_unref(toggleModalItem)()))
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createElementVNode("div", { class: "flex flex-col" }, null, -1)
            ])),
            _: 1
          }, 8, ["show"]),
          _renderSlot(_ctx.$slots, "default")
        ])
      ]),
      _createVNode(MobileFooterBlock)
    ])
  ]))
}
}

})