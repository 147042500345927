   // Создайте файл directives/ripple.js

  //  export default {
  //   mounted(el) {
  //     el.addEventListener('click', function (e) {
  //       const ripple = document.createElement('span');
  //       const rect = el.getBoundingClientRect();
  //       const size = Math.max(rect.width, rect.height);
  //       const x = e.clientX - rect.left - size / 2;
  //       const y = e.clientY - rect.top - size / 2;

  //       ripple.style.width = ripple.style.height = `${size}px`;
  //       ripple.style.left = `${x}px`;
  //       ripple.style.top = `${y}px`;
  //       ripple.className = 'ripple';

  //       el.appendChild(ripple);

  //       setTimeout(() => {
  //         ripple.remove();
  //       }, 600);
  //     });
  //   },
  // };
  
  export default {
    mounted(el:HTMLHtmlElement) {
      el.addEventListener('click', function (e:MouseEvent) {
        const ripple = document.createElement('span');
        const rect = el.getBoundingClientRect();
        const size= Math.max(rect.width, rect.height);
        const x = e.clientX - rect.left - size / 2;
        const y = e.clientY - rect.top - size / 2;
  
        ripple.style.width = ripple.style.height = `${size}px`;
        ripple.style.left = `${x}px`;
        ripple.style.top = `${y}px`;
        ripple.className = 'ripple';
  
        el.appendChild(ripple);
  
        setTimeout(() => {
          ripple.remove();
        }, 600);
      });
    },
  };