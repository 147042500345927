<!-- Модальное окно -->
<script setup>
import { onMounted, onUnmounted, watch} from 'vue';
 // eslint-disable-next-line
const props = defineProps({
    show: {
        type: Boolean,
        default: false,
    },
    maxWidth: {
        type: String,
        default: 'sm',
    },
    closeable: {
        type: Boolean,
        default: true,
    },
    viewBtnClose: {
        type: Boolean,
        default: true,
    },
    visible: {
        type: Boolean,
        default: true,
    },

});
 // eslint-disable-next-line
const emit = defineEmits(['close']);

watch(
    () => props.show,
    () => {
        if (props.show) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = null;
        }
    }
);

const close = () => {
    // props.show=false
    // alert(  props.show)
    // props.show=false
    emit('close');
    // if (props.closeable) {

    //     emit('close');
    // }
};

const closeOnEscape = (e) => {
    if (e.key === 'Escape' && props.show) {
        close();
    }
};

onMounted(() => document.addEventListener('keydown', closeOnEscape));

onUnmounted(() => {
    document.removeEventListener('keydown', closeOnEscape);
    document.body.style.overflow = null;
});

// const maxWidthClass = computed(() => {
//     return {
//         sm: 'sm:max-w-sm',
//         md: 'sm:max-w-md',
//         lg: 'sm:max-w-lg',
//         xl: 'sm:max-w-xl',
//         '2xl': 'sm:max-w-4xl',
//     }[props.maxWidth];
// });
</script>

<template>
    <teleport to="body">
        <!-- fade slide -->


        <!-- <transition leave-active-class="duration-4000"> -->
        <div v-show="show" :class="[props.visible ? 'visible' : 'invisible']"
            class="block sm:hidden justify-items-end fixed inset-0 overflow-y-auto px-0 py-2 sm:px-0 z-[20] "
            scroll-region>

            <!-- поле -->
            <!-- <transition enter-active-class="ease-out duration-4000" enter-from-class="opacity-0"
                    enter-to-class="opacity-100" leave-active-class="ease-in duration-2000"
                    leave-from-class="opacity-100" leave-to-class="opacity-0"> -->

            <!-- поле -->
            <transition name="fade">
            <div v-show="show" class="fixed inset-0 transform transition-all" @click="close">
                <div class="absolute inset-0 bg-gray-700 dark:bg-zinc-900 opacity-40 " />
            </div>
        </transition>
            <!-- zoom slid fade -->
            <transition name="fade">
                <!-- </transition> -->

                <!-- форма -->
                <!-- <transition enter-active-class="ease-out duration-4000"
                    enter-from-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to-class="opacity-100 translate-y-0 sm:scale-100" leave-active-class="ease-in duration-4000"
                    leave-from-class="opacity-100 translate-y-0 sm:scale-100"
                    leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"> -->


                <div v-show="show"
                    class="overflow-y-auto max-h-[calc(100vh-2rem)] w-72 h-full bg-body-light dark:bg-body-dark rounded-lg overflow-hidden shadow-xl transform transition-all backdrop-blur-md ">


                    <div v-if="props.viewBtnClose" class="p-1 flex justify-end">
                        <button @click="close()"
                            class="border border-blue-600/10 hover:border-blue-600 py-2 px-4 rounded shadow-md transform transition-all duration-200 active:scale-95 active:shadow-lg">
                            X
                        </button>
                    </div>







                    <slot v-if="show" />
                </div>
            </transition>
        </div>
        <!-- </transition> -->

    </teleport>
</template>
<style scoped>
/* Fade (Плавное появление и исчезновение) */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

/* Slide (Сдвиг) */
.slide-enter-active,
.slide-leave-active {
    transition: transform 0.5s;
}

.slide-enter-from,
.slide-leave-to {
    transform: translateX(100%);
}

/* Zoom (Увеличение/уменьшение) */
.zoom-enter-active,
.zoom-leave-active {
    transition: transform 0.5s;
}

.zoom-enter-from,
.zoom-leave-to {
    transform: scale(0.8);
}

/* ### Bounce (Прыжок) */
.bounce-enter-active, .bounce-leave-active {
  animation: bounce 0.5s;
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
  40% {transform: translateY(-30px);}
  60% {transform: translateY(-15px);}
}


/* Стилизация общей полосы прокрутки */
.overflow-y-auto::-webkit-scrollbar {
    @apply w-2;
    /* Ширина полосы прокрутки */
}

/* Стилизация ползунка (thumb) */
.overflow-y-auto::-webkit-scrollbar-thumb {
    background: #55555517;
    @apply rounded;
    /* Цвет и закругление углов */
}

/* Стилизация области трека (track) */
.overflow-y-auto::-webkit-scrollbar-track {
    @apply bg-body-light;
    /* Цвет фона трека */
}


.overflow-y-auto::-webkit-scrollbar-thumb:hover {
    background: #5555555e;
}
</style>