import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "z-[30] fixed top-0 left-0 right-0 text-white bg-header-light dark:bg-header-dark shadow-md py-4 px-2" }
const _hoisted_2 = { class: "xl:container mx-auto" }
const _hoisted_3 = { class: "flex w-full justify-between" }
const _hoisted_4 = { class: "flex gap-x-2" }

import ThemeButton from "@/components/items/ThemeButton.vue";
import ButtonHomeOnline from "@/components/items/header/ButtonHomeOnline.vue";

// LAYOUT-SERVICE
// import { layoutService } from '@/service/layoutService';
// const { toggleModalItem, getInterfaceState, toggleOffcanvasBlockDefault } = layoutService();


// import { useRouter } from 'vue-router'
// import { useRoute } from 'vue-router'
// const router = useRouter();
// const route = useRoute();

// const goToWelcomePage = () => {
//     router.push({ path: `/main/welcome` });
// }


// import { authService } from '@/service/authService';
// const { isAuth } = authService();



export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderBlock',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(ButtonHomeOnline)
        ]),
        _cache[0] || (_cache[0] = _createElementVNode("span", { class: "text-2xl font-bold text-white/80" }, "PROAPI", -1)),
        _createElementVNode("div", null, [
          _createVNode(ThemeButton)
        ])
      ])
    ])
  ]))
}
}

})