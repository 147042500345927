/* eslint-disable */ 
const serviceName = 'fetchAPI' 
// fetchAPI получение данных по приложению чат
// import { api } from '@/router/fetchAPI';

//LOG-SERVICE
import {LogService} from '@/service/logService';
const log = new LogService(serviceName);


//URL-API-ROUTER
import { urlAPI } from '@/router/urlAPI';

// инициализация при создании
// function initFetchAPI() { 
//     return
// }
// Определим типы данных для ответа от API


interface ApiResponse {
    success: boolean;
    // eslint-disable-next-line 
    data: any | null; // Можно заменить на конкретный тип данных, если известно заранее
    message?: string;
}

import { authService } from '@/service/authService';
const { getTokenAuth } = authService();

// Функция для выполнения запроса с обработкой ошибок
async function fetchData(url: string, options?: RequestInit): Promise<ApiResponse> {    
    try {
    // Выполняем fetch запрос
    const response = await fetch(url, options);
    // Проверяем, успешен ли HTTP-ответ
    if (!response.ok) {
        // Если произошла ошибка, выбрасываем исключение с текстом ошибки
        throw new Error(`error status code: ${response.status}`);
    }
    // Пробуем распарсить JSON из ответа
    try {
        const data = await response.json();
        // Успешно возвращаем результат
        log.info('fetch - успех',{url:url, data:data, options:options})
        return {
            success: true,
            data,
        };        
    } catch (error) {
        log.info('fetch - ошибка',{url:url, error:error, options:options})
        return {
            success: false,
            data: null,
            message: "ошибка запроса",
    };        
} 
    } catch (error) {
    // Если произошла ошибка, возвращаем её в стандартном формате
    
    if (error instanceof SyntaxError) {
        log.error('ошибка при запросе - failed to parse json response.',{"error":error})
        // Ошибка парсинга JSON
        return {
        success: false,
        data: null,
        message: "ошибка при запросе - failed to parse json response.",
        };
    } else {
        // Другая ошибка (например, сеть недоступна или API не отвечает)
        console.log(error)
        log.error("неизвестная ошибка при запросе",{"error": error } )
        return {
        success: false,
        data: null,
        message: error instanceof Error ? error.message : "An unknown error occurred.",
        };
    }
    }
}

// 1.1 вспомогательная функция - для опредления приложения
function splitString(input: string): string[] {
    // Проверяем, содержит ли строка точку
    if (input.includes('.')) {
        // Разбиваем строку по точке
        return input.split('.');
    }
    // Если точки нет, возвращаем строку как единственный элемент массива
    return [input];
}
  

// 1.2 Функция для доступа к значению
// eslint-disable-next-line 
function getPropertyByPath(routeName:any) {
    // eslint-disable-next-line 
    return routeName.reduce((current:any, key:any) => current && current[key], urlAPI);
  }
  
        
// eslint-disable-next-line 
export const api = {
    request: async (routeName:string,body?:any)=>{ 
        // 1. получаю маршрут
        const route = getPropertyByPath(splitString(routeName))
        if(!route || !('url' in route)){
            log.error(`ERROR не получилось распознать по имени маршрут`, {'routeName':routeName,'route':route,'body':body} ); 
            return {'success':false , "error":'не получилось распознать по имени маршрут'}    
        }
        log.info(`+api.request ${routeName}`, {'routeName':routeName,'route':route,'body':body} ); 

        // console.log('123',route)
        // alert()    

        const options:any = {
            method: route.method,
            headers: {
                'Content-Type': 'application/json',                
            }, 
        }
        if('IsAuthenticated' in route) // true)
        {
            if(route.IsAuthenticated==true){
                options['headers']['Authorization'] = 'Token ' + getTokenAuth.value
            }         
        }
        else{ // если нет по IsAuthenticated - умолчанию все защищены           
            options['headers']['Authorization'] = 'Token ' + getTokenAuth.value
        }   
        
        if (body != undefined){
            options['body'] = JSON.stringify(body)            
        } 

        // log.info('options',{'options':options,'url':urlAPI[`${routeName}`].url});
        const result = await fetchData(route.url,options); 
        if (result.success) {
            // log.info("api.request - успешно:", {'options':options,'url':urlAPI[`${routeName}`].url, 'data':result.data} );
            return {'success':true, data: result.data}
             
        } else {
            // log.error("api.request - ошибка:", {'options':options,'url':urlAPI[`${routeName}`].url, 'result.message':result.message} ); 
            return {'success':false , "error":result.message}                       
        }               
    }
}