import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import AuthenticatedPageLayout from "@/layouts/AuthenticatedPageLayout.vue";
// import TwoBlock from "@/components/blocks/TwoBlock.vue";
// import MobileFooterBlock from "@/components/blocks/MobileFooterBlock.vue";
// import HeaderBlock from "@/components/blocks/HeaderBlock.vue";
// import MenuItem from "@/components/items/MenuItem.vue";
// import OffcanvasBlock from '@/components/blocks/OffcanvasBlock.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'DefaultLayout',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(AuthenticatedPageLayout, null, {
    default: _withCtx(() => [
      _createVNode(_component_router_view)
    ]),
    _: 1
  }))
}
}

})