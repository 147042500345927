export interface Auth {
  status: string;
  isAuth: boolean;
}

export enum AuthMode {
  BeginState = "",
  AwaitAuth = "AwaitAuth",
}

