<template>
    <!-- :class="[routeName == 'WelcomePage' ? 'border-2 border-red-600' : '']" -->
    <button @click="goToWelcomePage()"
        class="border border-blue-600/10 hover:border-blue-600 text-white font-bold py- px-6 rounded shadow-md transform transition-all duration-200 active:scale-95 active:shadow-lg">
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-house"
            viewBox="0 0 16 16">
            <path
                d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5z" />
        </svg>
    </button>

    <!-- :class="[routeName == 'LoginPage' ? 'border-2 border-red-600' : '']" -->
    <button @click="goToLoginPage()"
        class="border border-blue-600/10 hover:border-blue-600 text-white font-bold py-2 px-6 rounded shadow-md transform transition-all duration-200 active:scale-95 active:shadow-lg">
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-person"
            viewBox="0 0 16 16">
            <path
                d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
        </svg>
    </button>
    <button @click="goToChatPage()"
        class="border border-blue-600/10 hover:border-blue-600 text-white font-bold py-2 px-6 rounded shadow-md transform transition-all duration-200 active:scale-95 active:shadow-lg">
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-chat-dots"
            viewBox="0 0 16 16">
            <path
                d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
            <path
                d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9 9 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.4 10.4 0 0 1-.524 2.318l-.003.011a11 11 0 0 1-.244.637c-.079.186.074.394.273.362a22 22 0 0 0 .693-.125m.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6-3.004 6-7 6a8 8 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a11 11 0 0 0 .398-2" />
        </svg>
    </button>

    <button @click="toggleOffcanvasBlockDefault()"
        class="border border-blue-600/10 hover:border-blue-600 text-white font-bold py-2 px-6 rounded shadow-md transform transition-all duration-200 active:scale-95 active:shadow-lg"
        aria-haspopup="dialog" aria-expanded="false" aria-controls="hs-offcanvas-right"
        data-hs-overlay="#hs-offcanvas-right">
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-list"
            viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5" />
        </svg>
    </button>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'
import { useRoute } from 'vue-router'
import { ref, Ref, onMounted,onUpdated, onBeforeUnmount, watch } from 'vue';

// LAYOUT-SERVICE
import { layoutService } from '@/service/layoutService';
const { toggleOffcanvasBlockDefault } = layoutService();

//LOG-SERVICE
import {LogService} from '@/service/logService';
const log = new LogService('MobileFooterItem');

// определяем переменные
const router = useRouter();
const route = useRoute();

// название маршрута
const routeName: Ref<string> = ref('')

// создание постав
const goToLoginPage = () => {

    router.push({ name: `HomePage` });
    routeName.value = route.name as string;
}
const goToWelcomePage = () => {

    router.push({ path: `/main/welcome` });
    routeName.value = route.name as string;
}
const goToChatPage = () => {

router.push({ path: `/chat/main` });
routeName.value = route.name as string;
}
// Наблюдатель, следящий за изменениями `count`
// newVal, oldVal
watch(routeName, () => {

    // newVal
    // console.log(`routeName изменен с ${oldVal} на ${newVal}`);
});

// Создание вычисляемого свойства
// const newRouteName = computed(() => routeName.value + '---');
onUpdated(() => {
    // scrollToBottom();
    log.info('onUpdated MobileFooterItem');
});
// Действия при монтировании компонента
onMounted(() => {
    routeName.value = route.name as string;
    log.info('onMounted MobileFooterItem');    
});
// Действия при размонтировании компонента
onBeforeUnmount(() => {
    // console.log('Компонент MobileFooterBlock будет размонтирован');
});

</script>
