<!-- Модальное окно -->
<script setup>
import { computed, onMounted, onUnmounted, watch } from 'vue';
 // eslint-disable-next-line
const props = defineProps({
    show: {
        type: Boolean,
        default: false,
    },
    maxWidth: {
        type: String,
        default: 'sm',
    },
    closeable: {
        type: Boolean,
        default: true,
    },
    viewBtnClose: {
        type: Boolean,
        default: true,
    },
    visible: {
        type: Boolean,
        default: true,
    },

});
 // eslint-disable-next-line
const emit = defineEmits(['close']);

watch(
    () => props.show,
    () => {
        if (props.show) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = null;
        }
    }
);

const close = () => {
    // props.show=false
    // alert(  props.show)
    // props.show=false
    emit('close');
    // if (props.closeable) {

    //     emit('close');
    // }
};

const closeOnEscape = (e) => {
    if (e.key === 'Escape' && props.show) {
        close();
    }
};

onMounted(() => document.addEventListener('keydown', closeOnEscape));

onUnmounted(() => {
    document.removeEventListener('keydown', closeOnEscape);
    document.body.style.overflow = null;
});

const maxWidthClass = computed(() => {
    return {
        sm: 'sm:max-w-sm',
        md: 'sm:max-w-md',
        lg: 'sm:max-w-lg',
        xl: 'sm:max-w-xl',
        '2xl': 'sm:max-w-4xl',
    }[props.maxWidth];
});
</script>

<template>
    <teleport to="body">
        <transition leave-active-class="duration-400">
            <div v-show="show" :class="[props.visible ? 'visible' : 'invisible']"
                class="content-end sm:content-center fixed inset-0 overflow-y-auto px-2 py-2 sm:px-2 z-50"
                scroll-region>

                <!-- поле -->
                <transition enter-active-class="ease-out duration-200" enter-from-class="opacity-0"
                    enter-to-class="opacity-100" leave-active-class="ease-in duration-200"
                    leave-from-class="opacity-100" leave-to-class="opacity-0">
                    <div v-show="show" class="fixed inset-0 transform transition-all" @click="close">
                        <div class="absolute inset-0 bg-gray-700 dark:bg-zinc-900 opacity-40" />
                    </div>
                </transition>

                <!-- форма -->
                <transition enter-active-class="ease-out duration-200"
                    enter-from-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to-class="opacity-100 translate-y-0 sm:scale-100" leave-active-class="ease-in duration-200"
                    leave-from-class="opacity-100 translate-y-0 sm:scale-100"
                    leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">


                    <div v-show="show"
                        class="mt-0 md:mt-16 mb-16 md:mb-0 mx-auto bg-body-light dark:bg-body-dark rounded-lg overflow-hidden shadow-xl transform transition-all backdrop-blur-md bg-white/20 "
                        :class="maxWidthClass">


                        <div v-if="props.viewBtnClose" class="p-1 flex justify-end">
                            <button @click="close()"
                                class="border border-blue-600/10 hover:border-blue-600 py-2 px-4 rounded shadow-md transform transition-all duration-200 active:scale-95 active:shadow-lg">
                                X
                            </button>
                        </div>







                        <slot v-if="show" />
                    </div>
                </transition>
            </div>
        </transition>
    </teleport>
</template>
