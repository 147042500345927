   <template>


    <!-- active:scale-95  w-full -->
    <div
      class="relative overflow-hidden flex disabled:opacity-50 disabled:pointer-events-none cursor-pointer disabled:bg-red-500 font-bold rounded shadow-md transform transition-all duration-200 active:shadow-lg bg-white/10 dark:bg-white/5"
      :class="dynamicClasses" @click="handleClick" disabled v-ripple>

      <div :class="[align == 'center' ? 'justify-center w-full ' : '']" class="flex flex-row items-center gap-x-2">

        <!-- спиннер загрузки -->
        <div v-if="load" class="spinner"></div>



        <!-- <div class="outline-none"> -->


        <div v-if="!load" v-html="IconSvg"></div>
        <!-- <svg  xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
            class="bi bi-house" viewBox="0 0 16 16">
            <path
              d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5z" />
          </svg> -->
        <slot></slot>

        <!-- </div> -->

      </div>



    </div>


  </template>

<script setup>

import { computed } from 'vue';
// eslint-disable-next-line
const emit = defineEmits(['click']);
// eslint-disable-next-line
const props = defineProps({
  align: {
    type: String,
    default: 'left',
  },
  load: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: 'button',
  },
  level: {
    type: String,
    default: 'primary',
  },
  color: {
    type: String,
    default: 'blue',
  },
  size: {
    type: String,
    default: 'medium',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  border: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
    default: '',
  },


});

function handleClick(event) {
  if (!props.disabled) {
    emit('click', {
      event: event
    });
  }
  else {
    // alert('disabled');
  }

}

// const getClass = computed(() => {
//   return {
//     [`btn-${props.size}`]: true,
//     'btn-disabled': props.disabled,

//   }
// });

// const getStyle = computed(() => {
//   return {
//     // backgroundColor: props.disabled ? 'gray' : props.color,
//   }
// });
const IconSvg = computed(() => {
  let itemSvg = '';
  switch (props.icon) {
    case 'enter':
      itemSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
              class="bi bi-box-arrow-in-right" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0z" />
              <path fill-rule="evenodd"
                d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z" />
            </svg>`
      break;
    case 'home':
      itemSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
            class="bi bi-house" viewBox="0 0 16 16">
            <path
              d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5z" />
          </svg>`
      break;
    case 'exit':
      itemSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor"
          class="bi bi-box-arrow-in-left" viewBox="0 0 16 16">
          <path fill-rule="evenodd"
            d="M10 3.5a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 1 1 0v2A1.5 1.5 0 0 1 9.5 14h-8A1.5 1.5 0 0 1 0 12.5v-9A1.5 1.5 0 0 1 1.5 2h8A1.5 1.5 0 0 1 11 3.5v2a.5.5 0 0 1-1 0z" />
          <path fill-rule="evenodd"
            d="M4.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H14.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708z" />
        </svg>`
      break;
    case 'edit':
    itemSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
  <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
</svg>`
    break;
    case 'light':
    itemSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-brightness-high" viewBox="0 0 16 16">
  <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6m0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8M8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0m0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13m8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5M3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8m10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0m-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0m9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707M4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708"/>
</svg>`
    break;
    case 'dark':
    itemSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-brightness-high-fill" viewBox="0 0 16 16">
  <path d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0M8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0m0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13m8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5M3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8m10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0m-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0m9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707M4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708"/>
</svg>`
    break;


    

   


      


    default:
      break;
  }
  return itemSvg
});





const dynamicClasses = computed(() => {

  // Предположим, что props содержит информацию,
  // на основе которой нужно динамически вернуть объект.
  // const props = { /* Здесь ваши входные данные */ };
  const classes = [];
  // Пример логики для возвращения различного объекта.
  switch (props.level) {
    case 'danger':
      classes.push('text-danger-light dark:text-danger-dark')
      break;
    case 'primary':
      classes.push('text-primary-light dark:text-primary-dark')
      break;
    case 'neutral':
      classes.push('text-neutral-light dark:text-neutral-dark')
      break;
    case 'success':
      classes.push('text-success-light dark:text-success-dark')
      break;
    case 'warning':
      classes.push('text-warning-light dark:text-warning-dark')
      break;
    case 'light':
      classes.push('text-white dark:text-white')
      break;
    default:
      classes.push('text-primary-light dark:text-primary-dark ')
      break;
  }

  switch (props.border) {
    case true:
      switch (props.level) {
        case 'danger':
          classes.push('border border-danger-light dark:border-danger-dark')
          break;
        case 'primary':
          classes.push('border border-primary-light dark:border-primary-dark')
          break;
        case 'neutral':
          classes.push('border border-neutral-light dark:border-neutral-dark')
          break;
        case 'success':
          classes.push('border border-success-light dark:border-success-dark')
          break;
        case 'warning':
          classes.push('border border-warning-light dark:border-warning-dark')
          break;
        default:
          break;
      }
      break;
    case false:
      classes.push('')
      break;
    default:
      break;
  }
  

  props.disabled ? classes.push('btn-disabled') : null;
  classes.push(`btn-${props.size}`)

  return classes;

  // else if (props.type === 'secondary') {
  //   return {
  //     backgroundColor: 'grey',
  //     color: 'black',
  //     border: '1px solid grey',
  //   };
  // } else {
  //   return {
  //     backgroundColor: 'white',
  //     color: 'black',
  //     border: '1px solid black',
  //   };
  // }
});




</script>

<style scoped>
.spinner {
  width: 25px;
  height: 25px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top-color: #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;

}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}


.btn-medium {
  padding: 10px 20px;
  font-size: 16px;
}

.btn-large {
  padding: 15px 30px;
  font-size: 20px;
}

.btn-small {
  padding: 6px 16px;
  font-size: 12px;
}

.btn-disabled {
  cursor: not-allowed;
}


.no-select {
  -webkit-user-select: none;
  /* Для браузеров на базе WebKit (например, Safari) */
  -moz-user-select: none;
  /* Для Firefox */
  -ms-user-select: none;
  /* Для Internet Explorer */
  user-select: none;
  /* Стандартное свойство */
}
</style>

