<!-- на меню кнопка отвечающая за переходы -->
<template>
    <!-- hover:border hover:border-blue-500  -->
    <div v-ripple @mouseenter="isHovered = true" @mouseleave="isHovered = false" :style="hoverStyle()"
        :class="[props.path == route.path ? ' bg-white/20 dark:bg-black/30' : '']"
        class="relative overflow-hidden flex flex-row text-md select-none cursor-pointer border border-white/10 dark:bg-body-dark rounded transform transition duration-300 ease-in-out hover:shadow-md focus:outline-none">

        <div class="py-3 px-2">

            <slot />
            <!-- <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-house"
                viewBox="0 0 16 16">
                <path
                    d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5z" />
            </svg> -->
        </div>
        <div class="flex items-center">

            <div class="font-bold uppercase ">
                <slot name="name" /> {{ props.name }}
            </div>
            <!-- <p class="text-sm">
                <slot name="description" />{{ props.description }}
            </p> -->
        </div>

    </div>
</template>
<script setup lang="ts">
import { ref, Ref } from 'vue';
// import { defineProps } from 'vue';
// import { useRouter } from 'vue-router'
import { useRoute } from 'vue-router'
// const router = useRouter();
const route = useRoute();

// Определяем и типизируем пропсы компонента
// const props = defineProps<{
//     name: {
//         type:string,
//         default:''
//     };
//     description: {
//         type:string,
//         default:''
//     };
// }>();
 // eslint-disable-next-line
const props = defineProps<{
    name: string;
    description: string;
    path?: string;
}>();
// const defaultProps = {
//   name: '',
//   description: '',
// };
// определяем переменные
const isHovered: Ref<boolean> = ref(false)
const hoverStyle = () => {
    return {
        transition: 'opacity 0.5s',
        opacity: isHovered.value ? 1 : 0.8,

    };
}

// document.querySelectorAll('.ripple').forEach(button => {
//         button.addEventListener('click', function (e) {
//             const rect = this.getBoundingClientRect();
//             const ripple = document.createElement('span');

//             const diameter = Math.max(rect.width, rect.height);
//             const radius = diameter / 2;

//             ripple.style.width = ripple.style.height = `${diameter}px`;
//             ripple.style.left = `${e.clientX - rect.left - radius}px`;
//             ripple.style.top = `${e.clientY - rect.top - radius}px`;

//             ripple.classList.add('ripple-effect');
//             this.appendChild(ripple);

//             setTimeout(() => {
//                 ripple.remove();
//             }, 600);
//         });
//     });
</script>
<style scoped lang="scss">

// .ripple {
//     position: absolute;
//     border-radius: 50%;
//     background: rgba(141, 37, 37, 0.3);
//     transform: scale(0);
//     animation: ripple-animation 0.6s linear;
//     pointer-events: none;
//     z-index: 0;
// }

// @keyframes ripple-animation {
//     to {
//         transform: scale(4);
//         opacity: 0;
//     }
// }
</style>