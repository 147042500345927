
type ChatType = "public" | "private";

export interface Chat {
  id: number; // Название чата может быть null или undefined
  name?: string | null; // Название чата может быть null или undefined
  participants: User[]; // Массив участников чата
  created_at: string; // Дата создания чата
  updated_at: string; // Дата последнего обновления чата
  type: ChatType; // Тип чата: публичный или закрытый
}

export enum EnumCurrentMessageMode {
  DefaultMode = "",
  EditMode = "edit",
}

type MessageStatus =
  | ""             
  | "draft"       // Черновик
  | "send"        // отправлено и доставлено на сервер
  | "delivered"   // доставлено клиенту
  | "read"        // Прочитано получателем
  | "failed"      // Ошибка
  | "deleted"     // Удалено
  | "archived"    // Архивировано
  | "scheduled"   // Запланировано
  | "pending"     // В ожидании
  | "replied"     // Получен ответ
  | "spam";       // Помечено как спам

export interface Message {
  id: number; 
  chat_id: number;          // Связь с конкретным чатом
  sender: User;           // Отправитель сообщения
  author: number;           // Автор сообщения
  text: string;           // Текст сообщения
  status: MessageStatus;  // Статус сообщения
  status_at: string;        // Дата последнего обновления статуса
  created_at: string;       // Когда сообщение было отправлено
  is_read: boolean;       // Статус прочтения сообщения
}