import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

// import { createPinia } from 'pinia'
// import "preline/preline";
import "./assets/tailwind.css";
import './assets/variables.css';

import ripple from './assets/ripple'
// import Centrifuge from "centrifuge";

import {LogService} from '@/service/logService';
const log = new LogService('main');

log.info('main')
// const pinia = createPinia()
// .use(pinia)
const app = createApp(App)
app.directive('ripple', ripple);

// app.config.errorHandler = (err: any, vm: any, info: any) => {
//     // log.critical(`CRITICAL: ${err.toString()}\nИнфо: ${info.toString()}`)
//     log.critical(`CRITICAL: ${err+''}`)
// };

app.use(router).mount("#app");
// import{useAuthStore}from "@/stores/authStore";
// const authStore = useAuthStore();
// authStore.initialize()
