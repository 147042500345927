<template>
  
    <div :class="['notification-list', position]" v-if="items.length">
      <div 
        class="notification backdrop-blur-md bg-black/60" 
        v-for="notification in items" 
        :key="notification.id"
      >
        {{ notification.message }}
      </div>
    </div>
  </template>
  
  <script lang="ts" setup>
  import { ref, computed } from "vue";
  
  // import { defineExpose } from 'vue';

// import { defineProps } from "vue";
 // eslint-disable-next-line
const props = defineProps({

        items: {
            type: Array,
            default: () => [],
        },

        propsPosition: {
            type: String,
            default: 'top-right',
        },

        propsDefaultDuration: {
            type: Number,
            default: 3000,
        },

});



  interface Notification {
    id: number;
    message: string;
    duration: number; // время в миллисекундах для удаления уведомления
  }
  
  // type Position = 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left';
  
  // Reactive список уведомлений
  const items = ref<Notification[]>([]);
  
  // Параметры можно передавать как props (или настроить по умолчанию)
  // const propsPosition = ref<Position>('top-right'); 
  // const propsDefaultDuration = ref<number>(3000); // Уведомление исчезает через 3000 мс
  
  // Добавление уведомления
  function addNotification(message: string, duration?: number) {
    const id = Date.now(); // Уникальный id по текущему времени
    items.value.push({
      id,
      message,
      duration: duration ?? props.propsDefaultDuration,
    });
  
    // Удаляем уведомление через `duration` мс
    setTimeout(() => {
      removeNotification(id);
    }, duration ?? props.propsDefaultDuration);
  }
  
  // Удаление уведомления по id
  function removeNotification(id: number) {
    items.value = items.value.filter(n => n.id !== id);
  }
  
  // Позиция уведомления
  const position = computed(() => {
    switch (props.propsPosition) {
      case 'top-right':
        return 'top-right';
      case 'top-left':
        return 'top-left';
      case 'bottom-right':
        return 'bottom-right';
      case 'bottom-left':
        return 'bottom-left';
      default:
        return 'top-right';
    }
  });
  
  // Экспортируем методы для добавления уведомления (его можно вызывать из других компонентов)
  // export { addNotification };

   // eslint-disable-next-line
  defineExpose({
    addNotification
  });
  </script>
  
  <style scoped>
  .notification-list {
    position: absolute;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .top-right {
    top: 10px;
    right: 10px;
  }
  
  .top-left {
    top: 10px;
    left: 10px;
  }
  
  .bottom-right {
    bottom: 10px;
    right: 10px;
  }
  
  .bottom-left {
    bottom: 10px;
    left: 10px;
  }
  
  .notification {
    
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    animation: bounceIn 0.3s ease-out;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  } 
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}


  </style>
  