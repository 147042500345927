/* eslint-disable */ 
// centrifugeService - отвечает за функционирование области чата
// import { centrifugeService } from '@/service/centrifugeService';
// const { destroyCurrentMessage } = centrifugeService();


// VUE
import { ref, computed} from 'vue';
const serviceName = 'centrifugeService'
//LOG-SERVICE
import {LogService} from '@/service/logService';
const log = new LogService(serviceName);

import { authService } from '@/service/authService';
const { getCurrentUser } = authService();

import { api } from '@/service/chatAPI';

import { chatService } from '@/service/chatService';
const { onChatServiceEventCentrifuge } = chatService();
// ROUTE
// import { useRouter } from 'vue-router'
// import { useRoute } from 'vue-router'
// const router = useRouter();
// enum AuthMode {
//     AwaitAuth = "AwaitAuth",
// }

// // dev STATE -
// interface Chat {
//     id: number;
//     name: string
//     messages: Array<any>,
// }

// STATE
// interface ConfigState {
// //   currentChatID: number,
//   // currentMessageIndex: number,
//   // interface: any,
//   // currentMessageMode: string,
// }

// CREATE-STATE
const state = ref<any>({
    isSubscribeToPersonalChannel:false,
    //   chats: [
    //     { id: 1, name: '11233', messages: [{ id: 1, author: 'Иван', text: 'test1' }, { id: 2, author: 'Петр', text: 'test1' }] },
    //     { id: 2, name: 'ffff', messages: [{ id: 1, author: 'Иван', text: 'test2' }, { id: 2, author: 'Петр', text: 'test2' }] },
    //     {
    //       id: 3, name: '2222', messages: [{ id: 1, author: 'Иван', text: 'test3' }, { id: 2, author: 'Петр', text: 'test3' }, { id: 3, author: 'Иван', text: 'test3' }, { id: 4, author: 'Петр', text: 'test3' }
    //         , { id: 6, author: 'Иван', text: 'test3' }, { id: 27, author: 'Петр', text: 'test3' }, { id: 91, author: 'Иван', text: 'test3' }, { id: 32, author: 'Петр', text: 'test3' }
    //         , { id: 31, author: 'Иван', text: 'test3' }, { id: 43, author: 'Петр', text: 'test3' }, { id: 55, author: 'Иван', text: 'test3' }, { id: 342, author: 'Петр', text: 'test3' }
    //       ]
    //     },
    //   ],
    //   currentChatID: 0,
    //   currentMessageID: 0,
    //   currentMessageIndex: 0,
    //   currentMessageMode: '',
    // modeAuth: '', // например идёт ожидангие авторизациия, ошибка авторизациия
    count: 0,  //пример
    //   inputMessage: { text: '' },
    interface: { isVisibleModal: false, isVisibleOffcanvas: false, isFreeBlockForMessage: false, isVisibleModalDebug: false, input: { text: '' } } //модальное isVisibleModal
});


// let connect_token = ''
// let subscribe_token = ''

// _ ФУНКЦИЯ ЗАГРУЗКИ СОСТОЯНИЯ localStorage
function _loadState() {
    try {
        const serializedState = localStorage.getItem(serviceName);
        return serializedState === null ? undefined : JSON.parse(serializedState);
    } catch (err) {
        log.error('Ошибка загрузки состояния в localStorage:', err)
        return undefined;
    }
}

// _ ФУНКЦИЯ СОХРАНЕНИЕ СОСТОЯНИЯ
// function _saveState() {
//     try {
//         const serializedState = JSON.stringify({});
//         localStorage.setItem(serviceName, serializedState);
//     } catch (err) {
//         log.error('Ошибка сохранения состояния в localStorage:', err)
//     }
// }

// ИНИЦИАЛИЗАЦИЯ ПРИ СОЗДАНИИ СЕРВИСА
function initCentrifugeService() {
    log.info('+initCentrifugeService()')
    // проверяем, как надо первоначально загрузить из файла или из хранилища
    if (_loadState() == null) {
        log.info('данные отсутствуют в хранилище')
    }
    else {
        log.info('данные присутствуют в хранилище loadState()', _loadState());
    }
    // независмо от хранилища всегда исполняется
}
initCentrifugeService()


import { Centrifuge } from "centrifuge";
let centrifuge: any
// определяем композитную функцию для управления состоянием
export function centrifugeService() {

    // GET-STATE Геттер для получения текущего состояния
    const isSubscribeToPersonalChannel = computed(() => state.value.isSubscribeToPersonalChannel);  //пример
    //   const getCurrentChat = computed(() => state.value.chats.find(item => item.id === state.value.currentChatID));
    //   const getCurrentMessageIndex = computed(() => getCurrentChat.value.messages.findIndex((message: any) => message.id === getCurrentMessageID.value));
    // получение токена для подключения
    // async function getTokenConnect() {
    //     log.info('+getTokenConnect')           
    //     const result = await api.request('getTokenConnectCentrifugeAPI')
    //     if (result.success){
    //         return {'success':true}
    //     }
    //     else{
    //         log.error("не удалось получить токен для соединения",result.error)
    //         return {'success':false}
    //     }
    //     log.info('-getTokenConnect')
    // }

        // событя 
        const eventSubscribePersonalChannel = (sub:any) => {
            log.info('+eventSubscribePersonalChannel')  
                    // Можно добавить дополнительную логику, например, подписку:
        // eventOnSubscribing(sub)
        // процесс подписки
        sub.on('subscribing', function (ctx:any) {
            state.value.isSubscribeToPersonalChannel = false
            // например когда сервер centrifuge отключился
            // handle new Publication data coming from channel "news".
            log.info('subscribing - в процессе подписки на Personal Channel',{ctx:ctx})
            // event_subscribed.value.channel.channel=ctx.channel
            // event_subscribed.value.personal_channel_state=ctx.reason             
        });    
        // успешно подписан
        sub.on('subscribed', function (ctx:any) {
            state.value.isSubscribeToPersonalChannel = true
            // handle new Publication data coming from channel "news".   
            // event_subscribed.value.channel=ctx
            // if(event_subscribed.value.channel.channel=='personal#'+user.value.id){
            //     event_subscribed.value.personal_channel_state="connected"                
            // }
            log.info('Подписался subscribed()->subscribePersonalChannel',{ctx:ctx})            
        });  
        sub.on('publication', function (ctx:any) { 
            log.info('публикация publication->subscribePersonalChannel',{'ctx.data':ctx.data})
            switch (ctx.data.app_name) {
                case "chat":                  
                    onChatServiceEventCentrifuge(ctx.data)
                    break;            
                default:
                    alert("неизвестный канал - публикация")
                    break;
            }
            // event_publication.value = ctx.data    
        });
        sub.on('join', function(joinCtx:any) {
            log.info('join()->subscribePersonalChannel',{joinCtx:joinCtx})        
        });
        
        sub.on('leave', function(leaveCtx:any) {
            log.info('leave()->subscribePersonalChannel',{leaveCtx:leaveCtx})         
        });

        sub.on('error', (error:any) => {            
            if (error.code === 109) {
                console.error('Authorization error. Check your token:', error.message);
            } else {
                console.error('An error occurred:', error);
            }              
            log.error('error в eventSubscribePersonalChannel',{error:error+''})
        });

        };
        
    
    // function getTokenSubscribe(endpoint:any) {
    //   return new Promise((resolve, reject) => {
    //     fetch(endpoint, {
    //       method: 'POST',
    //       headers: new Headers({ 'Content-Type': 'application/json' }),
    //     })
    //       .then(res => {
    //         if (!res.ok) {                    
    //           throw new Error(`Error status code ${res.status}`);
    //         }
    //         return res.json();
    //       })
    //       .then(data => {
    //         log.info('получили данные из запроса customGetToken',data.token_subscribe);
    //         resolve(data.token_subscribe);
    //       })
    //       .catch(err => {
    //         log.error('ошибка при получении токена',{'err':err+''});    
    //         reject(err);
    //       });
    //   });
    // }
    // Создание 
    // personal:1
    const subscribePersonalChannel = async () => {
        log.info('+subscribePersonalChannel')
        // const sub = centrifuge.newSubscription('personal#1',{
        //     token: subscribe_token
        // })       
        if (getCurrentUser.value == null) {        
            log.error('при подписке на персональный канал пользователь = null');
            return {'success':false}
        }
        log.info('началась подписка на частный канал c user_id ',{'user_id':getCurrentUser.value.id,'url':`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_CENTRIFUGE_URL_GET_TOKEN_SUBSCRIBE_PERSONAL_CHANNEL}`});            
        // const sub:any = centrifuge.newSubscription(`personal#${getCurrentUser.value.id}`,{
        //     getToken: async function () {
        //         return await api.request('getTokenSubscribePersonalChannelCentrifugeAPI')
        //         // return getTokenSubscribe(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_CENTRIFUGE_URL_GET_TOKEN_SUBSCRIBE_PERSONAL_CHANNEL}`);
        //     },
        // }); 

        // const result = await api.request('getTokenSubscribePersonalChannelCentrifugeAPI')
       
        // if (result.success){ 

            const sub:any = centrifuge.newSubscription(`personal#${getCurrentUser.value.id}`,{
                
                token: (await api.request('getTokenSubscribePersonalChannelCentrifugeAPI')).data,
                getToken: async function () {
                    const result = await api.request('getTokenSubscribePersonalChannelCentrifugeAPI')
                    return result.success==true ? result.data : null;
                },               
            });
            
            
            // const sub:any = centrifuge.newSubscription(`personal#${getCurrentUser.value.id}`,{
            //     token: result.data            
            // });          

            eventSubscribePersonalChannel(sub)
            sub.subscribe();
            return {'success':true}
        // }
        // else{   
        //     return {'success':false}
        // } 

        
    };

    // событя 
    const eventConnectCentrifuge = (centrifuge:any) => {
        log.info('+eventConnectCentrifuge')  

        centrifuge.on("connect", function (ctx:any) {
            log.info("connect", ctx);
        });        
        centrifuge.on("disconnect", function (ctx:any) {
            log.info("disconnected", ctx);
        }); 
        centrifuge.on("connecting", function (ctx:any) {
            log.info("connecting", ctx);
        });            
        centrifuge.on("connected", function (ctx:any) {
            log.info("connected - подключился к centrifuge ", ctx);            
        });        
        centrifuge.on("join", function (ctx:any) {
            log.info("join", ctx);
        });        
        centrifuge.on("error", function (ctx:any) {            
            switch (ctx.type) {                
                case "connectToken":                 
                    log.error('on.error()->connectToken',{ctx:ctx, error:{type:ctx.type,message:{text:'не верный токен для соединения'}}}) 
                    break;  
                case "transport":                
                    // rкогда нет соединения с сервером centrifuge например перезагрузка
                    log.error('on.error->transport',{ctx:ctx, error:{type:ctx.type,message:{text:'нет связи'}}}) 
                    break;
                case "connect":                
                
                    log.error('on.error->connect',{ctx:ctx, error:{type:ctx.type,message:{text:'ошибка авторизации, возможно не верный токен'}}}) 
                    break;        
                default: 
                    log.error('on.error()->default',{ctx:ctx, error:{type:ctx.type,message:{text:'ошибка не определена'}}})
                    break;
            }
        });     
        
    };
    
    // Создание 
    const connectСentrifuge = async () => {
        log.info('+connectСentrifuge')       
        // const result = await api.request('getTokenConnectCentrifugeAPI')

        
        centrifuge = new Centrifuge(`${process.env.VUE_APP_CENTRIFUGE_URL}`, {
            token: (await api.request('getTokenConnectCentrifugeAPI')).data,
            getToken: async function () {
                const result = await api.request('getTokenConnectCentrifugeAPI')
                return result.success==true ? result.data : null;
            },   
  
        });
        eventConnectCentrifuge(centrifuge) 
        centrifuge.connect()

        // if (result.success){     

        //     centrifuge = new Centrifuge("ws://localhost:8111/connection/websocket", {
        //         token: result.data,  
        //     });
        //     eventConnectCentrifuge(centrifuge) 
        //     centrifuge.connect()
        //     return {'success':true}
        // }
        // else{
        //     log.error("не удалось содеиниться и получить токен для соединения",result.error)
        //     return {'success':false}
        // }  
        // centrifuge = new Centrifuge("ws://localhost:8111/connection/websocket", {
        //     token: connect_token,
        //     // _getToken: function (ctx): Promise<any> {
        //     //     log.info('getToken',ctx)
        //     //     return getToken(import.meta.env.VITE_DOMEN + '/centrifugo/token', ctx);
        //     // },
        // });       
            
        log.info('-connectСentrifuge')
    };



    
    // Подключение
     const centrifugeConnect = async () => {
        // получить токены

        // await getTokenConnect()
        // await getTokenSubscribePersonalChannel()        
        await connectСentrifuge()       
         
        await subscribePersonalChannel()

    };

    // Возвращаем геттеры и действия
    return {
        isSubscribeToPersonalChannel,
        centrifugeConnect,       
    };
}
