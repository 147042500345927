/* eslint-disable */ 
const serviceName = 'chatAPI' 
// chatAPI получение данных по приложению чат
// import { chatAPI } from '@/service/chatAPI';
// const { * } = chatAPI();

//LOG-SERVICE

import {LogService} from '@/service/logService';
const log = new LogService(serviceName);
//API ROUTER
import { urlAPI } from '@/router/urlAPI';

// инициализация при создании
function init() { 
    return
}
init()

// Определим типы данных для ответа от API
interface ApiResponse<T> {
    success: boolean;
    data: T; // Можно заменить на конкретный тип данных, если известно заранее
    message?: string;
}

import { authService } from '@/service/authService';
const { getTokenAuth } = authService();

// Функция для выполнения запроса с обработкой ошибок
async function fetchData(url: string, options?: RequestInit): Promise<ApiResponse<any>> {
    

    // пример options
    // {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify({
    //       title: 'My New Post',
    //       body: 'This is the content of the post.',
    //       userId: 1
    //     })
    //  }

    try {
    // Выполняем fetch запрос
    const response = await fetch(url, options);

    // Проверяем, успешен ли HTTP-ответ
    if (!response.ok) {
        // Если произошла ошибка, выбрасываем исключение с текстом ошибки
        throw new Error(`error status code: ${response.status}`);
    }

    // Пробуем распарсить JSON из ответа
    try {
        const data = await response.json();
        // Успешно возвращаем результат
        log.info('fetch - успех',{url:url, data:data, options:options})
        return {
            success: true,
            data,
        };        
    } catch (error) {
        log.info('fetch - ошибка',{url:url, error:error, options:options})
        return {
            success: false,
            data: null,
            message: "ошибка запроса",
        };
        
    }
    




    } catch (error) {
    // Если произошла ошибка, возвращаем её в стандартном формате
    
    if (error instanceof SyntaxError) {
        log.error('error - fetch',{"message":"Failed to parse JSON response.","error":error})
        // Ошибка парсинга JSON
        return {
        success: false,
        data: null,
        message: "Failed to parse JSON response.",
        };
    } else {
        // Другая ошибка (например, сеть недоступна или API не отвечает)
        log.error("error - fetch",{"error": error } )
        return {
        success: false,
        data: null,
        message: error instanceof Error ? error.message : "An unknown error occurred.",
        };
    }
    }
}


function createOptions(method:string,body?:any):RequestInit {
    // if (method=="GET"){
    //     return {
    //         method: method,
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': 'Token ' + getTokenAuth.value
    //         },            
    //     }
    // }
    // else{
        // console.log("body",body);

        if (body == undefined){
            return {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token ' + getTokenAuth.value
                },                
            }         
        }
        else{
            return {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token ' + getTokenAuth.value
                },
                body: JSON.stringify(body)
            }
        }
      
        

    // }   

}

// const handle = async (): Promise<any>  =>  { 
//     log.error("+getChatList "); 
//     // Вызываем fetchData и обрабатываем результат
//     const options = createOptions(urlAPI.chat.getChatListAPI.method);
//     const result = await fetchData(urlAPI.chat.getChatListAPI.url,options); 
//     // return result;       
//     if (result.success) {
//         log.info("успешно getChatListAPI:", result.data);
//         return {'success':true, data: result.data}
         
//     } else {
//         log.error("ошибка getChatListAPI", result.message); 
//         return {'success':false , "error":result.message}
                   
//     }     

//     log.error("-getChatList ");
// };

export const api = {
    request: async (routeName:string,body?:any)=>{ 
        log.info(`+api.request ${routeName}`, {'routeName':routeName,'body':body} );
       
        const options:any = {
            method: urlAPI.chat[`${routeName}`].method,
            headers: {
                'Content-Type': 'application/json',                
            }, 
        }

        if('IsAuthenticated' in urlAPI.chat[`${routeName}`]) // true)
        {
            if(urlAPI.chat[`${routeName}`].IsAuthenticated==true){
                options['headers']['Authorization'] = 'Token ' + getTokenAuth.value
            }         
        }
        else{ // если нет по IsAuthenticated - умолчанию все защищены           
            options['headers']['Authorization'] = 'Token ' + getTokenAuth.value
        }   
        
        if (body != undefined){
            options['body'] = JSON.stringify(body)            
        }    
        
        

        // log.info('options',{'options':options,'url':urlAPI.chat[`${routeName}`].url});
        const result = await fetchData(urlAPI.chat[`${routeName}`].url,options); 
        if (result.success) {
            // log.info("api.request - успешно:", {'options':options,'url':urlAPI.chat[`${routeName}`].url, 'data':result.data} );
            return {'success':true, data: result.data}
             
        } else {
            // log.error("api.request - ошибка:", {'options':options,'url':urlAPI.chat[`${routeName}`].url, 'result.message':result.message} ); 
            return {'success':false , "error":result.message, data: null}
                       
        }       
        
    }
}

// определяем композитную функцию для управления состоянием
export function chatAPI() {  




    // получить список чатов
    const getChatListAPI = async (): Promise<any>  =>  { 
        log.error("+getChatList "); 
        // Вызываем fetchData и обрабатываем результат
        const options = createOptions(urlAPI.chat.getChatListAPI.method);
        const result = await fetchData(urlAPI.chat.getChatListAPI.url,options); 
        // return result;       
        if (result.success) {
            log.info("успешно getChatListAPI:", result.data);
            return {'success':true, data: result.data}
             
        } else {
            log.error("ошибка getChatListAPI", result.message); 
            return {'success':false , "error":result.message}
                       
        }     

        log.error("-getChatList ");
    };

    // получить список сообщений
    const getMessageListAPI = async (chat_id:number): Promise<any>  =>  { 

        log.error("+getMessageListAPI "); 
        // Вызываем fetchData и обрабатываем результат
        const options = createOptions(urlAPI.chat.getMessageListAPI.method);
        const result = await fetchData(urlAPI.chat.getMessageListAPI.url(chat_id),options); 
        // return result;       
        if (result.success) {
            log.info("успешно getMessageListAPI:", result.data);
            return {'success':true, data: result.data}
             
        } else {
            log.error("ошибка getMessageListAPI", result.message); 
            return {'success':false , "error":result.message}
                       
        }     
    };

    // удалить
    const deleteMessageAPI = async (message_id:number): Promise<any>  =>  { 

        log.info("+deleteMessageAPI "); 
        // Вызываем fetchData и обрабатываем результат
        const options = createOptions(urlAPI.chat.deleteMessageAPI.method);
        const result = await fetchData(urlAPI.chat.deleteMessageAPI.url(message_id),options); 
        // return result;       
        if (result.success) {
            log.info("успешно deleteMessageAPI:", result.data);
            return {'success':true, data: result.data}
             
        } else {
            log.error("ошибка deleteMessageAPI", result.message); 
            return {'success':false , "error":result.message}
                       
        }   
        log.info("-deleteMessageAPI ");   
    };

    // обновить сообщение
    const updateMessageAPI = async (message_id:number,body:any): Promise<any>  =>  { 
        log.info("+updateMessageAPI "); 
        // Вызываем fetchData и обрабатываем результат
        const options = createOptions(urlAPI.chat.updateMessageAPI.method,body);
        const result = await fetchData(urlAPI.chat.updateMessageAPI.url(message_id),options); 
        // return result;       
        if (result.success) {
            log.info("успешно updateMessageAPI:", result.data);
            return {'success':true, data: result.data}
                
        } else {
            log.error("ошибка updateMessageAPI", result.message); 
            return {'success':false , "error":result.message}
                        
        }   
        log.info("-updateMessageAPI ");   
    };
    
    

    // Функция для выполнения запроса с обработкой ошибок
    // async function fetchData(): Promise<any> {       
        
    //     const apiUrl = "https://jsonplaceholder.typicode.com/todos/1";
    //     // const apiUrl = String(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CHAT}/chats`);
    //     // Вызываем fetchData и обрабатываем результат
    //     const result = await fetchData(apiUrl);        
    //     if (result.success) {
    //         console.log("API call succeeded:", result.data);
    //     } else {
    //         log.error("ошибка ", result.message);
    //         console.error("API call failed with message:", result.message);
    //     }        

    // }

  

    // Возвращаем геттеры и действия
    return {
        updateMessageAPI,
        getMessageListAPI,
        getChatListAPI,
        deleteMessageAPI
               
    };
}

