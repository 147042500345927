<template>
  <!-- bg-body-light text-text-light dark:bg-body-dark dark:text-text-dark -->
  <div class="flex flex-col h-screen xl:container mx-auto">

    <!-- HEADER -->
    <HeaderBlock />

    <!-- toggleOffcanvasBlockDefault(false) -->
    <OffcanvasBlockDefault :show="getInterfaceState.isVisibleOffcanvasBlockDefault"
      @close="toggleOffcanvasBlockDefault()" :closeable="false">
      <div class="p-4 space-y-3 pb-2">
        <!-- СПИСОК -->

        <MenuListDefault></MenuListDefault>
       

      </div>
    </OffcanvasBlockDefault>


    <!-- MAIN-CONTENT -->
    <div class="flex flex-1 pt-16 ">

      <!-- PC-LEFT-PANEL-BODY -->
      <aside
        class="pt-4 max-h-[calc(100vh-5rem)] min-h-[calc(100vh-8rem)] overflow-y-auto hidden sm:block w-72 flex-none p-2 pb-16 fixed inset-y-06 space-y-2 bg-menu-light dark:bg-menu-dark">
        <!-- СПИСОК -->
        <MenuListDefault></MenuListDefault>
      </aside>

      <!-- PAGE-BODY -->
      <div
        class="overflow-y-auto max-h-[calc(100vh-8rem)] p-2 pb-4 relative flex-1 ml-0 sm:ml-72 bg-chat-page-body-light dark:bg-chat-page-body-dark">

        <!-- CHAT-BODY-CONTENT-->
        <div class="flex flex-col gap-y-2">
          <!-- <button @click="notify">Добавить уведомление</button> -->
       
   
      
           <NotificationItem ref="notification" propsPosition="bottom-right"/>
         
          <!--DEBUG-PAGE -->
          <!-- getInterfaceState.isVisibleModalDebug -->
          <ModalItem :show="getInterfaceState.isVisibleModalItem" :closeable="false" @close="toggleModalItem()">
            <div class="flex flex-col">
              

              <!-- <span>getChats {{ getChats }}</span> -->
              <!-- <span>getCurrentChatID {{ getCurrentChatID }}</span>
              <span>getCurrentChat {{ getCurrentChat }}</span>
              <span>getCurrentMessageID {{ getCurrentMessageID }}</span>
              <span>isVisibleModal {{ getInterfaceState.isVisibleModal }}</span>
              <span>getCurrentMessageMode {{ getCurrentMessageMode }}</span>
              <span>getCurrentMessageMode {{ getInterfaceState }}</span> -->
            </div>
          </ModalItem>


          <slot/>

          <!--CHAT-MESSAGES -->
          <!-- <transition-group name="list">
            <MessageItem v-for="(message) in getCurrentChat.messages" :key="message.id" :name="message.author"
              :isCurrentMessage="message.id == getCurrentMessageID" :text="message.text"
              @click="setCurrentMessageID(message.id), setInterface('isVisibleModal', true)">
            </MessageItem>
          </transition-group> -->

          <!-- MODAL-ACTIONS-FOR-MESSAGES -->
          <!-- <ModalItem :show="getInterfaceState.isVisibleModal" :closeable="false"
            @close="setInterface('isVisibleModal', !getInterfaceState.isVisibleModal)">
            <div class="p-1 space-y-2">
              <button @click="destroyCurrentMessage()"
                class="w-full border border-primary-light/10 text-blue-500 font-bold ps-2 pe-3 py-2.5 rounded transform transition-all duration-200 active:scale-95 active:shadow-lg bg-body-light dark:bg-body-dark">
                УДАЛИТЬ
              </button>
              <button @click="editCurrentMessage()"
                class="w-full border border-primary-light/10 text-blue-500 font-bold ps-2 pe-3 py-2.5 rounded transform transition-all duration-200 active:scale-95 active:shadow-lg bg-body-light dark:bg-body-dark">
                РЕДАКТИРОВАТЬ
              </button>
              <button @click="clipboard()"
                class="w-full border border-primary-light/10 text-blue-500 font-bold ps-2 pe-3 py-2.5 rounded transform transition-all duration-200 active:scale-95 active:shadow-lg bg-body-light dark:bg-body-dark">
                КОПИРОВАТЬ
              </button>
            </div>
          </ModalItem> -->

          <!-- ПУСТОЙ БЛОК ДЛЯ КРАСИВОГО ДОБАВЛЕНИЯ СООБЩЕНИЯ -->
          <!-- <div v-if="getInterfaceState.isFreeBlockForMessage" class="bg-red-500 h-14"> </div> -->

        </div>
      </div>


      <!-- MOBILE-FOOTER -->
      <MobileFooterBlock></MobileFooterBlock>
    </div>







    <!-- MOBILE-MENU -->
    <!-- <OffcanvasBlock>
      <template #default>
        <div v-if="$slots.MenuList" class="space-y-2">
          <slot name="MenuList" />
        </div>
        <div v-else
          class="max-h-screen overflow-x-none overflow-y-auto space-y-2 px-4 pt-2 pb-[calc(13rem)] md:pb-[calc(9rem)]">
          <MenuListDefault></MenuListDefault>
        </div>
      </template>
    </OffcanvasBlock> -->


  </div>

</template>
<script setup lang="ts">

// СТАРЫЕ КОМПОНЕНТЫ - РЕВИЗИЯ
import MobileFooterBlock from "@/components/blocks/MobileFooterBlock.vue";
import HeaderBlock from "@/components/blocks/HeaderBlock.vue";
// import MenuItem from "@/components/items/MenuItem.vue";
// import OffcanvasBlock from '@/components/blocks/OffcanvasBlock.vue';
import MenuListDefault from "@/components/items/menu/MenuListDefault.vue";
import OffcanvasBlockDefault from "@/components/blocks/OffcanvasBlockDefault.vue";
import ModalItem from "@/components/blocks/ModalItem.vue";

// LAYOUT-SERVICE
import { layoutService } from '@/service/layoutService';
const { toggleModalItem, getInterfaceState, toggleOffcanvasBlockDefault } = layoutService();

import NotificationItem from '@/components/items/NotificationItem.vue';
import { ref } from 'vue';
const notification = ref()
// function notify() {
//   notification.value.addNotification("Новое уведомление!", 5000)
//   console.log(notification.value)
//   // notification.value.addNotification("Новое уведомление!", 5000)
//   // addNotification("Новое уведомление!", 5000); // Уведомление будет удалено через 5 секунд
// }
// import { centrifugeService } from '@/service/centrifugeService';
// const { centrifugeConnect } = centrifugeService();
// centrifugeConnect()

// // тестирование
// import { chatAPI } from '@/service/chatAPI';
// const { getChatListAPI } = chatAPI();
// getChatListAPI()

// Allocate Subscription to a channel.
// const sub = centrifuge.newSubscription('public_channel');

// // React on `news` channel real-time publications.
// sub.on('publication', function(ctx:any) {
//     console.log('publication',ctx.data);
// });

// // Trigger subscribe process.
// sub.subscribe();
// COMPONENTS
// import MessageItem from "@/components/items/chat/parts/MessageItem.vue";
// import MobileFooterBlock from "@/components/items/chat/parts/MobileFooterBlock.vue";
// import HeaderBlock from "@/components/items/chat/parts/HeaderBlock.vue";
// import OffcanvasBlock from '@/components/items/chat/parts/OffcanvasBlock.vue';
// import MenuListDefault from "@/components/items/menu/MenuListDefault.vue";
// import DialogItem from "@/components/items/chat/parts/DialogItem.vue";
// import DialogList from "@/components/items/chat/parts/DialogList.vue";
// import ModalBlock from "@/components/items/chat/parts/ModalBlock.vue";
// import DropdownItem from "@/components/items/chat/parts/DropdownItem.vue";
// import ModalItem from "@/components/items/chat/parts/ModalItem.vue";
// import OffcanvasBlockDefault from "@/components/items/chat/parts/OffcanvasBlockDefault.vue";

</script>
<style scoped>
/* Стилизация общей полосы прокрутки */
.overflow-y-auto::-webkit-scrollbar {
  @apply w-2;
  /* Ширина полосы прокрутки */
}

/* Стилизация ползунка (thumb) */
.overflow-y-auto::-webkit-scrollbar-thumb {
  background: #55555517;
  @apply rounded;
  /* Цвет и закругление углов */
}

/* Стилизация области трека (track) */
.overflow-y-auto::-webkit-scrollbar-track {
  @apply bg-body-light;
  /* Цвет фона трека */
}


.overflow-y-auto::-webkit-scrollbar-thumb:hover {
  background: #5555555e;
}




/* Стиль для вебкита-браузеров */
/* ::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1; 
}

::-webkit-scrollbar-thumb {
    background-color: #888; 
    border-radius: 6px;
    border: 3px solid #f1f1f1;
}

::-webkit-scrollbar-thumb:hover {
    background: #555; 
} */

/* Стиль для Firefox */
/* * {
    scrollbar-width: thin;
    scrollbar-color: #888 #6b6a6a;
} */
</style>