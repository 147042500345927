import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex z-[30] h-16 flex-row fixed left-0 bottom-0 w-full bg-mobile-footer-light dark:bg-mobile-footer-dark" }
const _hoisted_2 = { class: "flex w-full h-full items-center pl-0 sm:pl-72 xl:mx-auto xl:container gap-x-2" }
const _hoisted_3 = { class: "flex sm:hidden justify-between relative w-full ps-2 pt-0" }

import { useRoute } from 'vue-router'
import { ref, Ref, onMounted, onBeforeUnmount, watch } from 'vue';
import MobileFooterItem from "@/components/items/MobileFooterItem.vue";
// определяем переменные
// const router = useRouter();

export default /*@__PURE__*/_defineComponent({
  __name: 'MobileFooterBlock',
  setup(__props) {

// import { useRouter } from 'vue-router'
const route = useRoute();

// название маршрута
const routeName: Ref<string> = ref('')


// Наблюдатель, следящий за изменениями `count`
// newVal, oldVal
watch(routeName, () => {

  // newVal
  // console.log(`routeName изменен с ${oldVal} на ${newVal}`);
});
// Создание вычисляемого свойства
// const newRouteName = computed(() => routeName.value + '---');

// Действия при монтировании компонента
onMounted(() => {
  routeName.value = route.name as string;
  // console.log('Компонент MobileFooterBlock смонтирован');
});
// Действия при размонтировании компонента
onBeforeUnmount(() => {
  // console.log('Компонент MobileFooterBlock будет размонтирован');
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "hidden sm:flex justify-between relative w-full ps-2 pt-0" }, [
        _createElementVNode("span", { class: "font-bold text-lg text-white" }, "2025")
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(MobileFooterItem)
      ])
    ])
  ]))
}
}

})