import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import BtnBlock from '@/components/blocks/BtnBlock.vue';
// CENTRIFUGE
import { centrifugeService } from '@/service/centrifugeService';

export default /*@__PURE__*/_defineComponent({
  __name: 'ButtonHomeOnline',
  setup(__props) {

// LOGIN-AUTH
// import { authService } from '@/service/authService';
// const { isAuth } = authService();

const { isSubscribeToPersonalChannel } = centrifugeService();
// ROUTER
// import { useRouter } from 'vue-router'
// import { useRoute } from 'vue-router'
// const router = useRouter();
// const route = useRoute();

// const goToWelcomePage = () => {
//     router.push({ path: `/main/welcome` });
// }

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BtnBlock, {
    level: _unref(isSubscribeToPersonalChannel) ? '' : 'light',
    size: "small",
    icon: "home"
  }, null, 8, ["level"]))
}
}

})