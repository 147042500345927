import { defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'ChatLayout',
  setup(__props) {

// import ModalBlock from "@/components/items/chat/parts/ModalBlock.vue";
// import { chatService } from '@/service/chatService';
// const { destroyCurrentMessage } = chatService();
// import AuthenticatedPageLayout from "@/layouts/AuthenticatedPageLayout.vue";
// import TwoBlock from "@/components/blocks/TwoBlock.vue";
// import MobileFooterBlock from "@/components/blocks/MobileFooterBlock.vue";
// import HeaderBlock from "@/components/blocks/HeaderBlock.vue";
// import MenuItem from "@/components/items/MenuItem.vue";
// import OffcanvasBlock from '@/components/blocks/OffcanvasBlock.vue';

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_component_router_view, null, {
    default: _withCtx(({ Component }) => [
      (_openBlock(), _createBlock(_KeepAlive, null, [
        (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
      ], 1024))
    ]),
    _: 1
  }))
}
}

})