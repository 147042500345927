<template>


    <!-- :class="[isSubscribeToPersonalChannel ? 'text-success-light' : '']"  -->
    <!-- :class="[isSubscribeToPersonalChannel ? 'bg-lime-700/40' : '']" -->
    <BtnBlock :level="isSubscribeToPersonalChannel ? '' : 'light'" size="small" icon="home"></BtnBlock>
    <!-- <button v-ripple @click="goToWelcomePage()" :class="[isSubscribeToPersonalChannel ? 'bg-lime-500/20' : '']"
        class="relative overflow-hidden  hidden sm:block font-bold py-1 px-6 rounded shadow-md transform transition-all duration-200 active:scale-95 active:shadow-lg">
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
        class="bi bi-house" viewBox="0 0 16 16">
            <path
                d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5z" />
        </svg>
    </button> -->
</template>
<script setup lang="ts">
// LOGIN-AUTH
// import { authService } from '@/service/authService';
// const { isAuth } = authService();

import BtnBlock from '@/components/blocks/BtnBlock.vue';
// CENTRIFUGE
import { centrifugeService } from '@/service/centrifugeService';
const { isSubscribeToPersonalChannel } = centrifugeService();
// ROUTER
// import { useRouter } from 'vue-router'
// import { useRoute } from 'vue-router'
// const router = useRouter();
// const route = useRoute();

// const goToWelcomePage = () => {
//     router.push({ path: `/main/welcome` });
// }
</script>
<style scoped lang="scss"></style>
  