
const serviceName = 'chatService'
// chatService - отвечает за функционирование области чата
// import { chatService } from '@/service/chatService';
// const { setInterface, getInterfaceState, getChats, getCount,
//   increment, decrement, setCount, setActiveChatID, editCurrentMessage, getCurrentMessageMode,
//   getCurrentChatID, getCurrentChat, setCurrentMessageID, scrollToBottom, clipboard,
//   getCurrentMessageID, destroyCurrentMessage } = chatService();

// VUE
import { computed,reactive,toRefs } from 'vue';

import type { Message, Chat } from '@/types/chatType.ts';
import { EnumCurrentMessageMode } from '@/types/chatType';

//LOG-SERVICE
import {LogService} from '@/service/logService';
const log = new LogService(serviceName);

// chatAPI получение данных по приложению чат
import { chatAPI } from '@/service/chatAPI';
const { getChatListAPI,getMessageListAPI,deleteMessageAPI,updateMessageAPI} = chatAPI();

import { api } from '@/service/chatAPI';

import { authService } from '@/service/authService';
const { getCurrentUser } = authService();
// ВСПОМОГАТЕЛЬНЫЕ ФУНКЦИИ
// [...Array(3).fill(0).map(x => ({ first_name: '', last_name: '' }))]
// [
//   { id: 1, name: '11233', messages: [{ id: 1, author: 'Иван', text: 'test1' }, { id: 2, author: 'Петр', text: 'test1' }] },
//   { id: 2, name: 'ffff', messages: [{ id: 1, author: 'Иван', text: 'test2' }, { id: 2, author: 'Петр', text: 'test2' }] },
//   {
//     id: 3, name: '2222', messages: [{ id: 1, author: 'Иван', text: 'test3' }, { id: 2, author: 'Петр', text: 'test3' }, { id: 3, author: 'Иван', text: 'test3' }, { id: 4, author: 'Петр', text: 'test3' }
//       , { id: 6, author: 'Иван', text: 'test3' }, { id: 27, author: 'Петр', text: 'test3' }, { id: 91, author: 'Иван', text: 'test3' }, { id: 32, author: 'Петр', text: 'test3' }
//       , { id: 31, author: 'Иван', text: 'test3' }, { id: 43, author: 'Петр', text: 'test3' }, { id: 55, author: 'Иван', text: 'test3' }, { id: 342, author: 'Петр', text: 'test3' }
//     ]
//   },
// ]


// _ внутренняя функция генерация id
function generateUniqueCode(length = 5):number {
  // ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz
  const characters = '0123456789';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charactersLength);
    result += characters[randomIndex];
  }
  return Number(result);
}





// interface Chat -
// interface Chat {
//   id: number
//   name: string
//   messages: Array<any>
//   currentPageMessages: number // страница для пагинации данных
//   pageMessagesSize: number // количество постов на одну порцию
// }
type InterfaceChat = {
  isVisibleModal: boolean;
  isVisibleOffcanvas: boolean;
  isFreeBlockForMessage: boolean;
  isVisibleModalDebug: boolean;
}

type InputMessage = {
  text: string;
};



// interface ConfigState
interface ConfigState {
  currentChatID: number | null,
  currentMessageID: number | null,
  interface: InterfaceChat,
  inputMessage: InputMessage,
  chats: Array<Chat>
}



// interface State
interface State {
  init:boolean,
  chats: Array<Chat>
  searchСhats: Array<Chat> // найденные chats запросам
  messages: Array<Message>
  // chat: Chat
  // currentPageMessages: 0 // страница для пагинации данных
  // pageMessagesSize: 10 // количество постов на одну порцию
 
  currentChatID: number | null
  currentMessageID: number | null
  currentMessageMode: EnumCurrentMessageMode

  interfaceChat: InterfaceChat
  inputMessage: { text: string }
  // refContainer: any // скроллинг
}
// CREATE-STATE
const state = reactive<State>({
  
  // refContainer:null,
  
  init: false,
  chats: [], // тут будут главные загруженные чаты и которые будут доступны на фронте
  searchСhats: [], //NOT USE найденные chats запросам
  messages:[], // сообщения

  // это данные для подергивания
  currentChatID: null,
  currentMessageID: null,
  
  currentMessageMode: EnumCurrentMessageMode.DefaultMode,

  interfaceChat: { isVisibleModal: false, isVisibleOffcanvas: false, isFreeBlockForMessage: false, isVisibleModalDebug: false }, // input: { text: '' } - NOT USE модальное isVisibleModal
  
  inputMessage: { text: '' }
});


// _ ФУНКЦИЯ ЗАГРУЗКИ СОСТОЯНИЯ localStorage
function loadState() {
  try {
    const serializedState = localStorage.getItem(serviceName);
    return serializedState === null ? undefined : JSON.parse(serializedState);
  } catch (err) {
    log.error('Ошибка загрузки состояния в localStorage:', err)
    return undefined;
  }
}

// данные для сохранения
function createConfigState(): ConfigState {
  // const update_interface = state.interface
  // update_interface.isVisibleModal = false
  return {
    currentChatID: state.currentChatID,
    currentMessageID: 0, //сбрасываю текущее сообщение
    interface: state.interfaceChat,
    inputMessage: state.inputMessage,
    chats: state.chats
  }
}

// _ ФУНКЦИЯ СОХРАНЕНИЕ СОСТОЯНИЯ
function saveState() {
  try {
    const configState = createConfigState();
    const serializedState = JSON.stringify(configState);
    localStorage.setItem(serviceName, serializedState);
  } catch (err) {
    log.error('Ошибка сохранения состояния в localStorage:', err)
  }
}


// определяем композитную функцию для управления состоянием
export function chatService() { 

  // ИНИЦИАЛИЗАЦИЯ ПРИ СОЗДАНИИ СЕРВИСА
async function initChatService() {

  log.info('+initChatService()')
  // если уже было ранее инициализировано
  if (state.init){    
    return;  
  }

  // getChatListAPI()
  // проверяем, как надо первоначально загрузить из файла или из хранилища
  const storage: ConfigState = loadState();

  if (storage == null) {
    log.info('данные отсутствуют хрнаилище')
    // данных нет загружаю фейковыеы  
    // сюда приходя с сервера данные
    let result  = await getChatListAPI()
    if (result.success){  
      state.chats = result.data.chats   
      state.init = true
    }
    else{
      state.init = false    
      log.error('не удалось получить чаты getChatListAPI')      
    }

    result  = await getMessageListAPI(state.chats[0].id)
    if (result.success){  
      state.messages = result.data.messages   
      state.init = true
    }
    else{
      state.init = false      
      log.error('не удалось получить сообщения getMessageListAPI')   
      return
    }
    
    // saveState();

    // загружаю состояние
    if (state.chats.length > 0) {     
      state.currentChatID = state.chats[0].id      
    } else {
      state.currentChatID = null      
    }

    if (state.messages.length > 0) {     
      state.currentMessageID = state.messages[0].id      
    } else {
      state.currentMessageID = null      
    }

  }
  else {

    // загружаю последнее состояние
    state.currentChatID = storage.currentChatID
    state.currentMessageID = storage.currentMessageID
    state.interfaceChat = storage.interface
    state.inputMessage = storage.inputMessage

    let result  = await getChatListAPI()
    if (result.success){  
      state.chats = result.data.chats     
      state.init = true
    }
    else{
      state.init = false
      log.error('не удалось получить сообщения getChatListAPI') 
      return
    }

    if(state.currentChatID){
      result  = await getMessageListAPI(state.currentChatID)
      if (result.success){  
        state.init = true
        state.messages = result.data.messages   
      }
      else{
        state.init = false
        log.error('не удалось получить сообщения getMessageListAPI')
        return      
      }
    }
    
    
  }
  saveState(); 
}
  const getCurrentChat = computed(() => state.chats.find(chat => chat.id === state.currentChatID) || null); //state.chats.find(item => item.id === state.currentChatID)
  // const getCurrentMessageIndex = computed(() => state.messages.findIndex((message: Message) => message.id === state.currentMessageID) || null);
  const getCurrentMessageIndex = computed(() => {
    const index = state.messages.findIndex((message: Message) => message.id === state.currentMessageID);
    return index !== -1 ? index : null;
  });
  
  
  const getCurrentMessage = computed(() => state.messages.find(message => message.id === state.currentMessageID) || null);

  // при переходе на другой чат
  const setActiveChatID = async (chat_id: number) => {

    state.currentChatID = chat_id;
    state.currentMessageMode = EnumCurrentMessageMode.DefaultMode;
    state.currentMessageID = null;
    state.inputMessage.text = ''

    const result  = await getMessageListAPI(chat_id)
    if (result.success){  
      state.messages = result.data.messages   
    }
    else{
      log.error('в вызове getMessageListAPI не удалось получить сообщения getMessageListAPI')
      return   
    }    
    saveState()
  };

  //при нажатии на сообщение
  const setCurrentMessageID = async (message_id: number) => { 
    
    state.currentMessageID = message_id;
    state.currentMessageMode = EnumCurrentMessageMode.DefaultMode;    
    state.inputMessage.text = ''
    saveState()
    state.interfaceChat.isVisibleModal = true    
  };

  //УПРАВЛЕНИЕ ИНТЕРФЕЙСОМ - КОПИРОВАНИЕ В БУФФЕР ОБМЕНА
  const clipboard = () => {
    if(getCurrentMessage.value){
      try {
        navigator.clipboard.writeText(getCurrentMessage.value.text).then(function () { return; }, function (err) {
          log.error('Ошибка при копировании в буфер обмена Async: Could not copy text: ', err)
        });
      } catch (error) {
        log.error('Системная при копировании в буфер обмена', error + '')
      }
      state.interfaceChat.isVisibleModal = false
    }    
  }
  
  // для прокрутки
  function scrollToBottom() {
    // Найдите элемент на странице
    const element: HTMLElement | null = document.getElementById('chat-body');
    if (element) {
      // Используйте setTimeout для добавления задержки
      // element.scrollTo({
      //   top: element.scrollHeight,

      //   // Используйте 'smooth' для плавной прокрутки
      // });
      // behavior: 'smooth',
      setTimeout(() => {
        // Прокрутите элемент в начало
        element.scrollTo({
          top: element.scrollHeight,
          // behavior: 'smooth',
          // Используйте 'smooth' для плавной прокрутки
        });
      }, 100); // Задержка в миллисекундах (например, 2000 мс = 2 секунды)
    }
  }

  // при изменении режима редактирования
  const setCurrentMessageMode = (name: EnumCurrentMessageMode) => {
    log.info('setCurrentMessageMode', { name: name })
    state.currentMessageMode = name;
    state.inputMessage.text = ''
  };  

  //при удалении
  const destroyCurrentMessage = async () => { 

    if(state.currentMessageID){
      const result  = await deleteMessageAPI(state.currentMessageID)

      if (result.success && getCurrentMessageIndex.value!=null){
       
          state.messages.splice(getCurrentMessageIndex.value, 1);
          state.interfaceChat.isVisibleModal = false
          state.currentMessageID = null
      }
      else{
        log.error('при вызове destroyCurrentMessage произошла ошибка deleteMessageAPI',result)
        return   
      }  
    }
  };

  // редактирование сообщения 
  const editCurrentMessage = async () => {

    if(getCurrentMessageIndex.value!=null){
      state.inputMessage.text = state.messages[getCurrentMessageIndex.value].text
      state.currentMessageMode = EnumCurrentMessageMode.EditMode;
      state.interfaceChat.isVisibleModal = false;      
    }
    
  };
  
  // отправка отредактированного сообщения
  const sendEditMessage = async () => {
    if(getCurrentMessageIndex.value==null || state.currentMessageID==null){
      return
    }
    
    state.messages[getCurrentMessageIndex.value].text=state.inputMessage.text
    const result  = await updateMessageAPI(state.currentMessageID,{'text':state.inputMessage.text}) 
    state.currentMessageMode = EnumCurrentMessageMode.DefaultMode;
    state.inputMessage.text = '' 
      
    if (result.success){  
      saveState()        
      return {'success':true}      
    } 
    else{      
      return {'success':false}
    } 

  }

  // ДЕЙСТВИЯ ПРИ ОТПРАВКЕ СООБЩЕНИЯ 
  const sendNewMessage = async () => {
      const text = state.inputMessage.text
      state.inputMessage.text = ''
      if (getCurrentUser.value && state.currentChatID){
     
        const temp_id_message = generateUniqueCode(10);

        // 
        const new_message:Message = { 
          id: temp_id_message,
          chat_id:state.currentChatID,
          text: text,
          sender: getCurrentUser.value, 
          author: getCurrentUser.value.id,
          status:"",
          status_at:new Date()+'',
          created_at:new Date()+'',
          is_read:false
        }
        state.messages.unshift(new_message); 
        
        scrollToBottom()
        const result  = await api.request('sendMessageAPI',{
          'chat_id':state.currentChatID,
          'sender':getCurrentUser.value.id,
          'text':text, 
          'status':'',
          'status_at':new Date()})

        // for (let i = 0; i < 1000; i++) {
        //   api.request('sendMessageAPI',{
        //     'chat_id':getCurrentChatID,
        //     'sender':getCurrentUser.id,
        //     'text':getInputMessage.text, 
        //     'status':'',
        //     'status_at':new Date()})
        // }

        

        if (result.success){
          log.info('успешно sendMessageAPI',result)          
          // обновляю сообщение
          const index = state.messages.findIndex((message: Message) => message.id === temp_id_message)
          if(index>=0){
            console.log(result.data)
            state.messages[index]=result.data
            return {'success':true}
          }
          else{
            return {'success':false}
          }    
          
          // setTimeout(() => {
          // добавляю в конец               
          // }, 5000); // Задержка в миллисекундах (например, 2000 мс = 2 секунды) 
        }
        else{   
          const index = state.messages.findIndex((message: Message) => message.id === temp_id_message)
          state.messages[index].status_at=new Date()+''
          state.messages[index].status='failed'
          return {'success':false}
        }
      }
     
  };


  type MessageStatus =
  | "new_message"

  interface EventCentrifugo {
    app_name: string,
    type: MessageStatus,
    content: Message;  
  }

  // обновление от centrifuge
  const onChatServiceEventCentrifuge = async (event:EventCentrifugo) => {
    log.info('+onChatServiceEventCentrifuge')
    
    switch (event.type) {
      case 'new_message': {
        const message = event.content

        if(getCurrentUser.value){         
          if(message.sender.id!=getCurrentUser.value.id){
            state.messages.unshift(message) 
            scrollToBottom()         
          }
        }
        
        
        // alert(event.content.id_temp_create)
        // Заворачиваем блок case в {}
        // const find_message = state.messages.find(message => message.id == event.content.id_temp_create)
        // if(find_message){
        //   alert(1)
        // }
        break;
      }
             
       
      default:
        break;
    }
    
    console.log(event);
    
    // 
  }

  // ленивая загрузка посто из найденных
  // const loadSearchPostMore = () => {
  //   // const start = this.currentPage * this.pageSize;
  //   // const end = start + this.pageSize;
  //   // // Добавление новой порции постов в состояние 
  //   // this.posts.push.apply(this.posts, this.searchPosts.slice(start, end))
  //   // // Увеличение номера текущей страницы
  //   // this.currentPage++;
  // }



  // Возвращаем геттеры и действия
  return {
    ...toRefs(state),

    onChatServiceEventCentrifuge,    
    initChatService, //предвариетлньео получение данных и загрузка 
    
    clipboard,
    
    setCurrentMessageMode,
    editCurrentMessage,
    
    setActiveChatID,
    setCurrentMessageID,
    destroyCurrentMessage,
    sendNewMessage,
    sendEditMessage,    

    getCurrentChat,    
    getCurrentMessage

  };
}

