import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { useRouter } from 'vue-router'
// import { useRoute } from 'vue-router'
import MenuItem from "@/components/items/MenuItem.vue";

import { authService } from '@/service/authService';

export default /*@__PURE__*/_defineComponent({
  __name: 'MenuListDefault',
  setup(__props) {

const router = useRouter();
// const route = useRoute();

// import { ref, Ref } from 'vue';
// import { defineProps } from 'vue';
const { isAuth } = authService(); // login

// Определяем и типизируем пропсы компонента
// const props = defineProps<{
//     name: {
//         type:string,
//         default:''
//     };
//     description: {
//         type:string,
//         default:''
//     };
// }>();
// const props = defineProps<{
//     name?: string;
//     description?: string;
// }>();
// const defaultProps = {
//   name: '',
//   description: '',
// };
// определяем переменные
// const isHovered: Ref<boolean> = ref(false)
// const hoverStyle = () => {
//     return {
//         transition: 'opacity 0.5s',
//         opacity: isHovered.value ? 1 : 0.8,

//     };
// }

// document.querySelectorAll('.ripple').forEach(button => {
//         button.addEventListener('click', function (e) {
//             const rect = this.getBoundingClientRect();
//             const ripple = document.createElement('span');

//             const diameter = Math.max(rect.width, rect.height);
//             const radius = diameter / 2;

//             ripple.style.width = ripple.style.height = `${diameter}px`;
//             ripple.style.left = `${e.clientX - rect.left - radius}px`;
//             ripple.style.top = `${e.clientY - rect.top - radius}px`;

//             ripple.classList.add('ripple-effect');
//             this.appendChild(ripple);

//             setTimeout(() => {
//                 ripple.remove();
//             }, 600);
//         });
//     });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(isAuth))
      ? (_openBlock(), _createBlock(MenuItem, {
          key: 0,
          onClick: _cache[0] || (_cache[0] = ($event: any) => {_unref(router).push({ path: `/main/home` });}),
          path: "/main/home",
          name: "Профиль",
          description: ""
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createElementVNode("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              width: "22",
              height: "22",
              fill: "currentColor",
              class: "bi bi-person-square opacity-70",
              viewBox: "0 0 16 16"
            }, [
              _createElementVNode("path", { d: "M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" }),
              _createElementVNode("path", { d: "M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1v-1c0-1-1-4-6-4s-6 3-6 4v1a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" })
            ], -1)
          ])),
          _: 1
        }))
      : _createCommentVNode("", true),
    (!_unref(isAuth))
      ? (_openBlock(), _createBlock(MenuItem, {
          key: 1,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(router).push({ path: `/main/login` }))),
          path: "/main/login",
          name: "ВХОД",
          description: ""
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createElementVNode("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              width: "22",
              height: "22",
              fill: "currentColor",
              class: "bi bi-person",
              viewBox: "0 0 16 16"
            }, [
              _createElementVNode("path", { d: "M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" })
            ], -1)
          ])),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_unref(isAuth))
      ? (_openBlock(), _createBlock(MenuItem, {
          key: 2,
          name: "DEBUG",
          description: "",
          onClick: _cache[2] || (_cache[2] = ($event: any) => {_unref(router).push({ path: `/main/debug` });}),
          path: "/main/debug"
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createElementVNode("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              width: "16",
              height: "16",
              fill: "currentColor",
              class: "bi bi-code-square",
              viewBox: "0 0 16 16"
            }, [
              _createElementVNode("path", { d: "M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" }),
              _createElementVNode("path", { d: "M6.854 4.646a.5.5 0 0 1 0 .708L4.207 8l2.647 2.646a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 0 1 .708 0m2.292 0a.5.5 0 0 0 0 .708L11.793 8l-2.647 2.646a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708 0" })
            ], -1)
          ])),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})