// import { apiRoutes } from '@/router/apiRoutes';

// http://127.0.0.1:8000/api/main/
const VUE_APP_API_URL = process.env.VUE_APP_API_URL; // базовый URL для API



// eslint-disable-next-line
export const urlAPI:any = {
  login:{
    authAPI: {
      IsAuthenticated:false,
      method: "POST",
      url:`${VUE_APP_API_URL}login/auth`, // получить все
    },
    logoutAPI: {
      IsAuthenticated:true,
      method: "POST",
      url:`${VUE_APP_API_URL}login/logout`, // получить все
    },
  },
  log:{
    createLogAPI: {
      IsAuthenticated:false,
      method: "POST",
      url:`${VUE_APP_API_URL}log/logs/create/fontend`, // получить все
    },   
  },
  chat: {
    getChatListAPI: {
      method: "GET",
      url:`${VUE_APP_API_URL}chat/chats`, // получить все
    },
    getMessageListAPI: {
      method: "GET",
      url: (chat_id:number) => `${VUE_APP_API_URL}chat/messages/chat/${chat_id}`,
    },
    deleteMessageAPI: {
      method: "DELETE",
      url: (message_id:number) => `${VUE_APP_API_URL}chat/messages/${message_id}/delete`,
    },
    updateMessageAPI: {
      method: "PUT",
      url: (message_id:number) => `${VUE_APP_API_URL}chat/messages/${message_id}/update`,
    },
    sendMessageAPI: {
      method: "POST",
      url: `${VUE_APP_API_URL}chat/messages/send`
    },
    getTokenConnectCentrifugeAPI: {
      IsAuthenticated:true, //не присоединяем токена авторизации
      method: "POST",
      url: `${VUE_APP_API_URL}${process.env.VUE_APP_CENTRIFUGE_URL_GET_TOKEN_CONNECT}`,
    },
    getTokenSubscribePersonalChannelCentrifugeAPI: {
      IsAuthenticated:true, //не присоединяем токена авторизации
      method: "POST",
      url: `${VUE_APP_API_URL}${process.env.VUE_APP_CENTRIFUGE_URL_GET_TOKEN_SUBSCRIBE_PERSONAL_CHANNEL}`
    },   
    
    
    // getOne: (id:number) => `${API_BASE_URL}/users/${id}`, // получить конкретного пользователя
    // create: `${API_BASE_URL}/users`, // создать пользователя
    // update: (id:number) => `${API_BASE_URL}/users/${id}`, // обновить пользователя
    // delete: (id:number) => `${API_BASE_URL}/users/${id}` // удалить пользователя
  },
//   posts: {
//     getAll: `${API_BASE_URL}/posts`, // получить все посты
//     getOne: (id:number) => `${API_BASE_URL}/posts/${id}`, // получить пост
//     create: `${API_BASE_URL}/posts`, // создать пост
//     update: (id:number) => `${API_BASE_URL}/posts/${id}`, // обновить пост
//     delete: (id:number) => `${API_BASE_URL}/posts/${id}` // удалить пост
//   }
};
