// createWebHashHistory, - это для електрон
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
// import HomeView from "@/views/HomeView.vue";
// beforeEnter: (to, from) => {
//   // reject the navigation
//   console.log('routes->WelcomePage',to, from)
//   return true;
// },
// import AuthenticatedPageLayout from "@/layouts/AuthenticatedPageLayout.vue";


// import { chatService } from '@/service/chatService';
// const { initializeChatService } = chatService();
// initializeChatService();


//LOG-SERVICE
import {LogService} from '@/service/logService';
const log = new LogService('index');


log.info('Начинается создаваться route')


import { authService } from '@/service/authService';
const { isAuth } = authService();





// // тестирование
// import { chatAPI } from '@/service/chatAPI';
// const { getChatListAPI } = chatAPI();
// getChatListAPI()


// , User
// import { AuthService } from '@/service/authService';
// const authService = new AuthService();

// Регистрация пользователя
// const newUser: User = { username: 'user1', password: 'pass123' };
// authService.register(newUser);
// Вход в систему
// authService.login('user1', 'pass123');

// Проверка аутентификации
// if (authService.isAuthenticated()) {
//   log.info('User is authenticated') 
// }
// else{
//   log.info('User NOT authenticated')  
// }

// Получение текущего пользователя
// const currentUser = authService.getCurrentUser();
// console.log('Current User:', currentUser);
// Выход из системы
// authService.logout();

import DefaultLayout from "@/layouts/DefaultLayout.vue";
import ChatLayout from "@/layouts/ChatLayout.vue";
import { chatService } from '@/service/chatService';
const { initChatService } = chatService();
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/main/welcome'
  },
  // {
  //   path: "/",
  //   name: "home",
  //   component: HomeView,
  // },
  {
    path: '/main/',
    component: DefaultLayout,
    children: [
      {
        path: '',
        redirect: '/main/welcome',
      },
      {
        path: 'login/:previos?',
        name: "LoginPage",
        component: () => import("@/pages/LoginPage.vue"),
        // to, from
        beforeEnter: (to, from, next) => {
          // Например, мы можем добавить простую проверку аутентификации          
          if (isAuth.value) {
            next({ name: 'HomePage' }); // Перенаправление на страницу входа            
          } else {
            // const previousQuery = from.query;            
            next(); // Запускает переход
          }
        },
        meta: { title: 'Авторизация' },

      },
      {
        path: 'welcome',
        name: "WelcomePage",
        component: () => import("@/pages/WelcomePage.vue"),
        // to, from
        beforeEnter: () => {
          return true;
        },
        

      },
      {
        path: 'home',
        name: "HomePage",
        component: () => import("@/pages/HomePage.vue"),
        // to, from
        beforeEnter: (to, from, next) => {
          // Например, мы можем добавить простую проверку аутентификации          
          if (isAuth.value) {
            next(); // Запускает переход           
          } else {
            router.push({ name: 'LoginPage', query: { previous: 'HomePage' } });
          }
        },
        meta: { title: 'Домашняя' },
      },
      {
        path: 'debug',
        name: "DebugPage",
        component: () => import("@/pages/DebugPage.vue"),
        // to, from
        beforeEnter: (to, from, next) => {
          // Например, мы можем добавить простую проверку аутентификации          
          if (isAuth.value) {
            next(); // Запускает переход           
          } else {
            router.push({ name: 'LoginPage', query: { previous: 'DebugPage' } });
          }
        },
      },
      // {
      //   path: 'chat',
      //   name: "ChatPage",
      //   component: () => import("@/pages/ChatPage.vue"),
      //   // to, from
      //   beforeEnter: () => {    

      //     return true;
      //   },

      // },
    ],
  },
  {
    path: '/sysadmin',
    name: "SysadminPage",
    component: () => import('@/pages/SysadminPage.vue')
  },
  {
    path: '/chat/',
    component: ChatLayout,
    children: [
      {
        path: '',
        redirect: '/chat/main',
      },
      {
        path: 'main',
        name: "ChatPage",
        component: () => import("@/pages/ChatPage.vue"),
        // to, from
        beforeEnter: (to, from, next) => {
          // Например, мы можем добавить простую проверку аутентификации          
          if (isAuth.value) {
            initChatService()
            next(); // Запускает переход           
          } else {
            router.push({ name: 'LoginPage', query: { previous: 'ChatPage' } });
          }
        },
        meta: { title: 'Сообщения' },

      },
      // {
      //   path: '/:id',
      //   name: "ChatPage",
      //   component: () => import("@/pages/ChatPage.vue"),
      //   // to, from
      //   beforeEnter: () => {    

      //     return true;
      //   },

      // },
    ],
  },
  {
    path: '/:pathMath(.*)*',
    name: "NotFound",
    component: () => import('@/pages/NotFound.vue')
  }


  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
  // {
  //   path: "/main/",
  //   name: "test",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/TestView.vue"),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// это для electron
// const router = createRouter({
//   history: createWebHashHistory(),
//   routes,
// });
router.beforeEach((to, from, next) => {
  // Установите заголовок страницы из мета-данных маршрута
  const defaultTitle = 'СУДАРТ';
  const doc:Document = document
  doc.title = to.meta.title as string || defaultTitle;
  next();
});


// import{useAuthStore}from "@/stores/authStore";
// const authStore = useAuthStore();
// authStore.initialize()
// to, from
// router.beforeEach(async () => {
//   // срабатывает перед маршрутизацией всегда
//   // router.push({path:'/main/welcome'}) 
//   return true
//   // return {name: 'LoginPage'}
// })


export default router;


